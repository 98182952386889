export default { 
    getGamesList: state => state.gamesList,
    getSelectedGame: state => state.selectedGame,
    getSelectedTier: state => state.selectedGame.selectedTier,
    getIdleAnimationOptions: state => state.selectedGame.idleAnimationOptions,
    getContentOptions: state => state.selectedGame.contentOptions,
    getBackgroundOptions: state => state.selectedGame.backgroundOptions,
    getTierOptions: state => state.selectedGame.tierOptions,
    getSegmentOptions: state => state.selectedGame.segmentOptions,
    getWinningAnimationsOptions: state => state.selectedGame.winningAnimationsOptions,
    getSegmentDefinitions: state => state.selectedGame.segment_definitions,
    getGameJSON: state => state.gameJSON,
    getCurrentStage: state => state.currentStage,
    getUploadContentError: state => state.uploadContentError,
    getDropdownsLoaded: state => state.dropdownsLoaded,
    getAssetList: state => state.assetList,
    getSharedAssetList: state => state.sharedAssetList,
    getPresignedUrl: state => state.selectedGame.presignedUrl,
    getBeforeUnloadEventFunction: state => state.beforeUnloadEventFunction,
    getCustomGameRuleContentId: state => state.customGameRuleContentId,
};