import Vue from 'vue'
import VueRouter from 'vue-router'
import Games from '@/views/Games'
import CreateWheel from '@/views/CreateWheel'
import LoginView from '@/views/LoginView'
import AssetLibrary from '@/views/AssetLibrary'
import GameRules from '@/views/GameRules'
import RulePreview from '@/views/RulePreview'

import { isAuthenticated } from "@/auth"

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: '/',
    name: 'games',
    component: Games,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/game/:id',
    name: 'game',
    component: CreateWheel,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/assets',
    name: 'assets',
    component: AssetLibrary,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/gamerules',
    name: 'gameRules',
    component: GameRules,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/rulepreview',
    name: 'rulePreview',
    component: RulePreview,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user is authenticated
    // Replace isAuthenticated() with your authentication logic
    if (!isAuthenticated()) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
