import { EMPTY_CONTENT_OBJECT, EMPTY_SEGMENT_OBJECT, randomPastelHex } from '@/utils/helper';

export default {
    cloneGame: (state) => {
        const clone = Object.assign({}, state.selectedGame);
        clone.name = "";
        clone.wheelGameId = null;
        clone.savedGameId = null;
        clone.groupId = null;
        clone.status = null;
        clone.type = null;
        clone.dateCreated = null;
        state.selectedGame = clone;
    },
    setGame: (state, game) => {
        state.selectedGame = game;
        state.selectedGame.tierOptions = [];
        state.selectedGame.idleAnimationOptions = [];
        state.selectedGame.contentOptions = [];
        state.selectedGame.animationOptions = [];
        state.selectedGame.segmentOptions = [];
        state.selectedGame.winningAnimationsOptions = [];        
    },
    setWheelGameId: (state, gameId) => {
        state.selectedGame.wheelGameId = gameId;
    },
    setWheelGamePresignedUrl: (state, presignedUrl) => {
        state.selectedGame.presignedUrl = presignedUrl;
    },
    setSavedGameId: (state, gameId) => {
        state.selectedGame.savedGameId = gameId;
    },
    setGameName: (state, gameName) => {
        state.selectedGame.name = gameName;
        for (let i = 0; i < state.selectedGame.tiers.length; i++) {
            state.selectedGame.tiers[i].name = state.selectedGame.name + "_tier_" + Number(i+2);
            state.selectedGame.tiers[i].nextRoundWheel = state.selectedGame.tiers.length > i + 1 ? state.selectedGame.name + "_tier_" + Number(i+3) : null;
            state.selectedGame.tiers[i].baseWheel = state.selectedGame.name;
        }
        state.selectedGame.nextRoundWheel = state.selectedGame.tiers[0] ? state.selectedGame.tiers[0].name : null;
    },
    setGameDescription: (state, gameDescription) => {
        state.selectedGame.description = gameDescription;
    },
    setIdleAnimationOptions: (state, idleAnimationOptions) => {
        state.selectedGame.idleAnimationOptions = idleAnimationOptions;
    },
    setContentOptions: (state, contentOptions) => {
        if (contentOptions && contentOptions.length > 0) {
            contentOptions = contentOptions.filter(element => !element.prefix.includes('/idle'));
        }
        state.selectedGame.contentOptions = contentOptions;
    },
    setSelectedTier: (state, selectedTier) => {
        state.selectedGame.selectedTier = selectedTier;
    },
    setTierOptions: (state, tierOptions) => {
        state.selectedGame.tierOptions.push(tierOptions);
    },
    setSegmentOptions: (state, segmentOptions) => {
        state.selectedGame.segmentOptions = segmentOptions;
    },
    clearSegmentOptions: (state) => {
        state.selectedGame.segmentOptions = [];
    },
    setWinningAnimationsOptions: (state, winningAnimationsOptions) => {
        state.selectedGame.winningAnimationsOptions = winningAnimationsOptions;
    },
    clearSegmentOptions: (state) => {
        state.selectedGame.segmentOptions = [];
    },
    setIdleAnimation: (state, idleAnimation) => {
        if (idleAnimation == null) {
            state.selectedGame.idle.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
            return;
        }
        if (idleAnimation.contentId == null) {
            idleAnimation.contentId = idleAnimation.id;
        }
        state.selectedGame.idle.asset = Object.assign({}, idleAnimation);
    },
    setIdleAnimationTime: (state, idleAnimationTime) => {
        state.selectedGame.idle.delay = idleAnimationTime;
    },
    setIdleAnimationDuration: (state, idleAnimationDuration) => {
        state.selectedGame.idle.duration = idleAnimationDuration;
    },
    setStartupAnimation: (state, startupAnimation) => {
        if (startupAnimation == null) {
            state.selectedGame.animation.startupAsset = Object.assign({}, EMPTY_CONTENT_OBJECT);
            return;
        }
        if (startupAnimation.contentId == null) {
            startupAnimation.contentId = startupAnimation.id;
        }
        state.selectedGame.animation.startupAsset = Object.assign({}, startupAnimation);
    },
    setStartupAnimationTime: (state, startupAnimationTime) => {
        state.selectedGame.animation.startupAnimationDelay = startupAnimationTime;
    },
    setStartupAnimationDuration: (state, startupAnimationDuration) => {
        state.selectedGame.animation.startupAnimationDuration = startupAnimationDuration;
    },
    setStartupAnimationLoop: (state, flag) => {
        state.selectedGame.animation.startupAnimationLoop = flag;
    },

    setTiers: (state, tiers) => {
        state.selectedGame.tiers = []; // TODO - warn user of a clearing of details if changed after details entered
        tiers.forEach(tier => {
            state.selectedGame.tiers.push(Object.assign({}, tier));
        });
        state.selectedGame.nextRoundWheel = tiers[0] ? tiers[0].name : null;
    },
    setTierInfo: (state, tier) => {
        state.selectedGame.tiers[tier.index].name = tier.name;
        state.selectedGame.tiers[tier.index].nextRoundWheel = tier.nextRoundWheel;
        state.selectedGame.tiers[tier.index].baseWheel = tier.baseWheel;
        state.selectedGame.tiers[tier.index].background = Object.assign({}, tier.background);
        state.selectedGame.tiers[tier.index].backgroundOverlay = Object.assign({}, tier.backgroundOverlay);
        state.selectedGame.tiers[tier.index].wheelOverlay = Object.assign({}, tier.wheelOverlay);
        state.selectedGame.tiers[tier.index].foreground = Object.assign({}, tier.foreground);
        state.selectedGame.tiers[tier.index].idle = Object.assign({}, tier.idle);
        state.selectedGame.tiers[tier.index].animation = Object.assign({}, tier.animation);
        
        // TODO - segments and segment_definitions
    },
    setSegmentsForTier: (state, payload) => {
        if(payload.tier == 0) {
            state.selectedGame.segment_definitions = [
                {
                    id: 0,
                    name: "Segment 1", //Segment value
                    group: null,
                    bonusSegment: false,
                    image: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 1,
                    name: "Segment 2",
                    group: null,
                    bonusSegment: false,
                    image: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 2,
                    name: "Segment 3",
                    group: null,
                    bonusSegment: false,
                    image: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex(),
                }
            ];
            state.selectedGame.segments = payload.segments;
        } else {
            // -1 because base wheel is not in this array 
            state.selectedGame.tiers[payload.tier -1].segment_definitions= [
                {
                    id: 0,
                    name: "Segment 1",
                    group: null,
                    bonusSegment: false,
                    image: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 1,
                    name: "Segment 2",
                    group: null,
                    bonusSegment: false,
                    image: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 2,
                    name: "Segment 3",
                    group: null,
                    bonusSegment: false,
                    image: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex(),
                }
            ];
            state.selectedGame.tiers[payload.tier - 1 ].segments = payload.segments;
        }
    },
    resetBaseGameBonusSegments: (state) => {
        if (state.selectedGame.tiers.length == 0) {
            for (let i = 0; i < state.selectedGame.segment_definitions.length; i++) {
                state.selectedGame.segment_definitions[i].bonusSegment = false
            }
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                state.selectedGame.segments[i].bonusSegment = false
            }
        } else {
            let lastTier = state.selectedGame.tiers[state.selectedGame.tiers.length - 1];
            for (let i = 0; i < lastTier.segment_definitions.length; i++) {
                lastTier.segment_definitions[i].bonusSegment = false
            }
            for (let i = 0; i < lastTier.segments.length; i++) {
                lastTier.segments[i].bonusSegment = false
            }
        }
    },
    setSegmentChoice: (state, payload) => {
        if(payload.tier == 0) {
            state.selectedGame.no_of_segments = payload.noOfSegments;
        } else {
            state.selectedGame.tiers[payload.tier -1].no_of_segments = payload.noOfSegments;
        }
    },
    setImageContentSrc: (state, data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i] && !data[i].mimeType.includes("image")) {
                continue;
            }
            let imageFileType = data[i].mimeType.split('/')[1];
            data[i].src = "data:image/" + imageFileType + ";base64," + data[i].data;
            if (data[i] && data[i].prefix.includes('segment')) {
                for (let x = 0; x < state.selectedGame.segment_definitions.length; x++) {
                    if (state.selectedGame.segment_definitions[x].image.src == null && data[i].displayName ==  state.selectedGame.segment_definitions[x].image.displayName) {
                        state.selectedGame.segment_definitions[x].image.src = data[i].src;
                    }
                }
                for (let x = 0; x < state.selectedGame.segments.length; x++) {
                    if (state.selectedGame.segments[x].image.contentId == data[i].id && state.selectedGame.segments[x].image.src == null) {
                        state.selectedGame.segments[x].image.src = data[i].src;
                    }
                }
                if (state.selectedGame.rules && state.selectedGame.rules.replacementSegment && state.selectedGame.rules.replacementSegment.image.src == null && 
                    data[i].displayName ==  state.selectedGame.rules.replacementSegment.image.displayName) {
                   state.selectedGame.rules.replacementSegment.image.src = data[i].src;
                }
                if (state.selectedGame.rules && state.selectedGame.rules.replacementGroup) {
                    for (let x = 0; x < state.selectedGame.rules.replacementGroup.length; x++) {
                        if (state.selectedGame.rules.replacementGroup[x] && state.selectedGame.rules.replacementGroup[x].image.src == null && 
                            data[i].displayName ==  state.selectedGame.rules.replacementGroup[x].image.displayName) {
                           state.selectedGame.rules.replacementGroup[x].image.src = data[i].src;
                        }
                    }
                }
                if (state.selectedGame.rules && state.selectedGame.rules.groups) {
                    for (let group in state.selectedGame.rules.groups) {
                        let groupObject = state.selectedGame.rules.groups[group];
                        for (let x = 0; x < groupObject.length; x++) {
                            if (state.selectedGame.rules.groups[group][x] && state.selectedGame.rules.groups[group][x].image.src == null && 
                                data[i].displayName == state.selectedGame.rules.groups[group][x].image.displayName) {
                               state.selectedGame.rules.groups[group][x].image.src = data[i].src;
                            }
                        }
                    }
                }

            } else if (data[i] && state.selectedGame.foreground.asset.displayName == data[i].displayName
                 && data[i].id == state.selectedGame.foreground.asset.contentId) {
                state.selectedGame.foreground.asset.src = data[i].src;
            } else if (data[i] && state.selectedGame.background.asset.displayName == data[i].displayName
                 && data[i].id == state.selectedGame.background.asset.contentId) {
                state.selectedGame.background.asset.src = data[i].src;
            } else if (data[i] && data[i].id == state.selectedGame.wheelOverlay.asset.contentId) {
                state.selectedGame.wheelOverlay.asset.src = data[i].src;
            } else if (data[i] && state.selectedGame.backgroundOverlay.asset.displayName == data[i].displayName) {
                state.selectedGame.backgroundOverlay.asset.src = data[i].src;
            }

            if (state.selectedGame.tiers.length > 0) {
                for (let j = 0; j < state.selectedGame.tiers.length; j++) {
                    if (data[i] && data[i].prefix.includes('segment')) {
                        for (let x = 0; x < state.selectedGame.tiers[j].segment_definitions.length; x++) {
                            if (state.selectedGame.tiers[j].segment_definitions[x].image.src == null && data[i].displayName ==  state.selectedGame.tiers[j].segment_definitions[x].image.displayName) {
                                state.selectedGame.tiers[j].segment_definitions[x].image.src = data[i].src;
                            }
                        }
                        for (let x = 0; x < state.selectedGame.tiers[j].segments.length; x++) {
                            if (state.selectedGame.tiers[j].segments[x].image.contentId == data[i].id && state.selectedGame.tiers[j].segments[x].image.src == null) {
                                state.selectedGame.tiers[j].segments[x].image.src = data[i].src;
                            }
                        }
                    } else if (data[i] && state.selectedGame.tiers[j].foreground.asset.displayName == data[i].displayName
                            && data[i].id == state.selectedGame.tiers[j].foreground.asset.contentId) {
                        state.selectedGame.tiers[j].foreground.asset.src = data[i].src;
                    } else if (data[i] && state.selectedGame.tiers[j].background.asset.displayName == data[i].displayName
                            && data[i].id == state.selectedGame.tiers[j].background.asset.contentId) {
                        state.selectedGame.tiers[j].background.asset.src = data[i].src;
                    } else if (data[i] && data[i].id == state.selectedGame.tiers[j].wheelOverlay.asset.contentId) {
                        state.selectedGame.tiers[j].wheelOverlay.asset.src = data[i].src;
                    } else if (data[i] && state.selectedGame.tiers[j].backgroundOverlay.asset.displayName == data[i].displayName) {
                        state.selectedGame.tiers[j].backgroundOverlay.asset.src = data[i].src;
                    }
                }
            }
        }
    },
    fillNullSegments(state) {
        for (let i = 0; i < state.selectedGame.segments.length; i++) {
            if (state.selectedGame.segments[i].image.src == null) {
                state.selectedGame.segments[i].fillStyle = randomPastelHex();
            }
        }

        for (let i = 0; i < state.selectedGame.tiers.length; i++) {
            for (let j = 0; j < state.selectedGame.tiers[i].segments.length; j++) {
                if (state.selectedGame.tiers[i].segments[j].image.src == null) {
                    state.selectedGame.tiers[i].segments[j].fillStyle = randomPastelHex();
                }
            }
        }
    },
    setSegmentDefinition(state, seg_defs) {
        state.selectedGame.segment_definitions = seg_defs;
    },
    addSegmentDefinition(state, payload) {
        var newSegment = Object.assign({}, EMPTY_SEGMENT_OBJECT);
        if(payload.tier == 0) {
            newSegment.id = state.selectedGame.segment_definitions.length +1;
            newSegment.name = "Segment " + newSegment.id;
            state.selectedGame.segment_definitions.push(Object.assign({}, newSegment));
        } else {
            newSegment.id = state.selectedGame.tiers[payload.tier -1].segment_definitions.length +1;
            newSegment.name = "Segment " + newSegment.id;
            state.selectedGame.tiers[payload.tier -1].segment_definitions.push(Object.assign({}, newSegment));
        }
    },
    setSegmentDefinitionName(state, payload) {
        let oldName ='';
        if(payload.tier == 0) {
            oldName = state.selectedGame.segment_definitions[payload.index].name;
            state.selectedGame.segment_definitions[payload.index].name = payload.name;
        } else {
            oldName = state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].name;
            state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].name = payload.name;
        }

        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (oldName == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].name = state.selectedGame.segment_definitions[payload.index].name;
                    state.selectedGame.segments[i].value = state.selectedGame.segment_definitions[payload.index].name;
                    state.selectedGame.segments[i].definitionName = state.selectedGame.segment_definitions[payload.index].name;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (oldName == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].name = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name;
                    state.selectedGame.tiers[payload.tier - 1].segments[i].value = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name;
                    state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name;
                }
            }
        }
    },
    setSegmentDefinitionGroup(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.segment_definitions[payload.index].group = payload.group;
        } else {
            state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].group = payload.group;
        }

        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].group = state.selectedGame.segment_definitions[payload.index].group;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].group = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].group;
                }
            }
        }
    },
    setSegmentDefinitionBonus(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.segment_definitions[payload.index].bonusSegment = payload.bonusSegment;
        } else {
            state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].bonusSegment = payload.bonusSegment;
        }

        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].bonusSegment = state.selectedGame.segment_definitions[payload.index].bonusSegment;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1]
                    .segments[i].bonusSegment = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].bonusSegment;
                }
            }
        }
    },
    removeSegmentDefinition(state, payload) {
        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segments[i].image && state.selectedGame.segments[i].image.contentId 
                        == state.selectedGame.segment_definitions[payload.index].image.contentId && 
                        state.selectedGame.segments[i].definitionName == state.selectedGame.segment_definitions[payload.index].name) {
                    const id = state.selectedGame.segments[i].id;
                    state.selectedGame.segments[i] = Object.assign({}, EMPTY_SEGMENT_OBJECT);
                    state.selectedGame.segments[i].id = id;
                }
            }
            state.selectedGame.segment_definitions.splice(payload.index, 1);
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segments[i].image && state.selectedGame.tiers[payload.tier - 1].segments[i].image.contentId 
                        == state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].image.contentId && 
                        state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName == state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name) {
                    const id = state.selectedGame.tiers[payload.tier - 1].segments[i].id;
                    state.selectedGame.tiers[payload.tier - 1].segments[i] = Object.assign({}, EMPTY_SEGMENT_OBJECT);
                    state.selectedGame.tiers[payload.tier - 1].segments[i].id = id;
                }
            }
            state.selectedGame.tiers[payload.tier - 1].segment_definitions.splice(payload.index, 1);
        }        
    },
    setForegroundContent(state, payload){
        
        if(payload.tier == 0) {
            if (payload.foregroundContent == null) {
                state.selectedGame.foreground.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.foregroundContent.contentId == null) {
                payload.foregroundContent.contentId = payload.foregroundContent.id;
            }
            state.selectedGame.foreground.asset = Object.assign({}, payload.foregroundContent);
        } else {
            if (payload.foregroundContent == null) {
                state.selectedGame.tiers[payload.tier -1].foreground.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.foregroundContent.contentId == null) {
                payload.foregroundContent.contentId = payload.foregroundContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].foreground.asset = Object.assign({}, payload.foregroundContent);
        }
    },
    setForegroundContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.foreground.assetSize = payload.assetSize
        } else {
            state.selectedGame.tiers[payload.tier -1].foreground.assetSize = payload.assetSize
        }
    },
    setForegroundRotation(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.foreground.rotation = payload.rotation;
        } else {
            state.selectedGame.tiers[payload.tier -1].foreground.rotation = payload.rotation;
        }
    },
    setBackgroundContent(state, payload){
        if(payload.tier == 0) {
            if (payload.backgroundContent == null) {
                state.selectedGame.background.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundContent.contentId == null) {
                payload.backgroundContent.contentId = payload.backgroundContent.id;
            }
            state.selectedGame.background.asset = Object.assign({}, payload.backgroundContent);
        } else {
            if (payload.backgroundContent == null) {
                state.selectedGame.tiers[payload.tier -1].background.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundContent.contentId == null) {
                payload.backgroundContent.contentId = payload.backgroundContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].background.asset = Object.assign({}, payload.backgroundContent);
        }
    },
    setBackgroundContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.background.assetSize = payload.assetSize;
        } else {
            state.selectedGame.tiers[payload.tier -1].background.assetSize = payload.assetSize;
        }
    },
    setBackgroundRotation(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.background.rotation = payload.rotation;
        } else {
            state.selectedGame.tiers[payload.tier -1].background.rotation = payload.rotation;
        }
    },
    setWheelOverlayContent(state, payload){
        
        if(payload.tier == 0) {
            if (payload.wheelOverlayContent == null) {
                state.selectedGame.wheelOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.wheelOverlayContent.contentId == null) {
                payload.wheelOverlayContent.contentId = payload.wheelOverlayContent.id;
            }
            state.selectedGame.wheelOverlay.asset = Object.assign({}, payload.wheelOverlayContent);
        } else {
            if (payload.wheelOverlayContent == null) {
                state.selectedGame.tiers[payload.tier -1].wheelOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.wheelOverlayContent.contentId == null) {
                payload.wheelOverlayContent.contentId = payload.wheelOverlayContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].wheelOverlay.asset = Object.assign({}, payload.wheelOverlayContent);
        }
    },
    setWheelOverlayContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.wheelOverlay.assetSize = payload.assetSize
        } else {
            state.selectedGame.tiers[payload.tier -1].wheelOverlay.assetSize = payload.assetSize
        }
    },
    setWheelOverlayRotation(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.wheelOverlay.rotation = payload.rotation;
        } else {
            state.selectedGame.tiers[payload.tier -1].wheelOverlay.rotation = payload.rotation;
        }
    },
    setBackgroundOverlayContent(state, payload){
        if(payload.tier == 0) {
            if (payload.backgroundOverlayContent == null) {
                state.selectedGame.backgroundOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundOverlayContent.contentId == null) {
                payload.backgroundOverlayContent.contentId = payload.backgroundOverlayContent.id;
            }
            state.selectedGame.backgroundOverlay.asset = Object.assign({}, payload.backgroundOverlayContent);
        } else {
            if (payload.backgroundOverlayContent == null) {
                state.selectedGame.tiers[payload.tier -1].backgroundOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundOverlayContent.contentId == null) {
                payload.backgroundOverlayContent.contentId = payload.backgroundOverlayContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].backgroundOverlay.asset = Object.assign({}, payload.backgroundOverlayContent);
        }
    },
    setBackgroundOverlayContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.backgroundOverlay.assetSize = payload.assetSize;
        } else {
            state.selectedGame.tiers[payload.tier -1].backgroundOverlay.assetSize = payload.assetSize;
        }
    },
    setBackgroundOverlayRotation(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.backgroundOverlay.rotation = payload.rotation;
        } else {
            state.selectedGame.tiers[payload.tier -1].backgroundOverlay.rotation = payload.rotation;
        }
    },
    setWinningAnimationSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.animationSize = payload.assetSize;
        } else {
            state.selectedGame.tiers[payload.tier -1].animationSize = payload.assetSize;
        }
    },
    setWinningAnimationDuration(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.animation.winningAnimationDuration = payload.winningAnimationDuration;
        } else {
            state.selectedGame.tiers[payload.tier -1].animation.winningAnimationDuration = payload.winningAnimationDuration;
        }
    },
    setWinningAnimationDelay(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.animation.winningAnimationDelay = payload.winningAnimationDelay;
        } else {
            state.selectedGame.tiers[payload.tier -1].animation.winningAnimationDelay = payload.winningAnimationDelay;
        }
    },
    setForegroundSrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.foreground.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].foreground.asset.src = payload.src;
        }
    },
    setBackgroundSrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.background.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].background.asset.src = payload.src;
        }
    },
    setWheelOverlaySrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.wheelOverlay.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].wheelOverlay.asset.src = payload.src;
        }
    },
    setBackgroundOverlaySrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.backgroundOverlay.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].backgroundOverlay.asset.src = payload.src;
        }
    },
    setIdleAnimationSrc(state, payload) {
        state.selectedGame.idle.asset.src = payload;
    },
    setStartupAnimationSrc(state, payload) {
        state.selectedGame.animation.startupAsset.src = payload;
    },
    setSegmentAnimation(state, payload) {
        if(payload.tier == 0) {
            if (payload.animation == null) {
                state.selectedGame.segment_definitions[payload.index].animation = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.segment_definitions[payload.index].animation = Object.assign({}, payload.animation);
                state.selectedGame.segment_definitions[payload.index].animation.contentId = payload.animation.contentId ?
                    payload.animation.contentId : state.selectedGame.segment_definitions[payload.index].animation.id;
                state.selectedGame.segment_definitions[payload.index].animation.id = payload.index;
            }
        } else {
            if (payload.animation == null) {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation = Object.assign({}, payload.animation);
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation.contentId = payload.animation.contentId ?
                payload.animation.contentId : state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation.id;
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation.id = payload.index;
            }
        }

        // If other segments share same animation, copy the src across to them
        if (payload.animation != null) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segments[i].animation && state.selectedGame.segments[i].animation.contentId == payload.animation.contentId && state.selectedGame.segments[i].animation.src == null) {
                    state.selectedGame.segments[i].animation.src = payload.animation.src;
                }
            }
            if (state.selectedGame.tiers.length > 0) {
                for (let j = 0; j < state.selectedGame.tiers.length; j++) {
                    for (let i = 0; i < state.selectedGame.tiers[j].segments.length; i++) {
                        if (state.selectedGame.tiers[j].segments[i].animation && state.selectedGame.tiers[j].segments[i].animation.contentId == payload.animation.contentId && state.selectedGame.tiers[j].segments[i].animation.src == null) {
                            state.selectedGame.tiers[j].segments[i].animation.src = payload.animation.src;
                        }
                    }
                }
            }
        }
        
        //Update all existing wheel segments with new segment definition
        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].animation = state.selectedGame.segment_definitions[payload.index].animation;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].animation = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].animation;
                }
            }
        }
    },
    setSegmentImage(state, payload) {
        if(payload.tier == 0) {
            if (payload.image == null) {
                state.selectedGame.segment_definitions[payload.index].image = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.segment_definitions[payload.index].image = Object.assign({}, payload.image);
                state.selectedGame.segment_definitions[payload.index].image.contentId = payload.image.contentId ?
                    payload.image.contentId : state.selectedGame.segment_definitions[payload.index].image.id;  //contentId is returned as 'id' from the DB
                state.selectedGame.segment_definitions[payload.index].image.id = payload.index;
                state.selectedGame.segment_definitions[payload.index].fillStyle = null;
            }
        }
        else {
            if (payload.image == null) {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image = Object.assign({}, payload.image);
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image.contentId = payload.image.contentId ?
                payload.image.contentId : state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image.id;
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image.id = payload.index;
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].fillStyle = null;
            }
        }

        //Update all existing wheel segments with new segment definition
        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].image = state.selectedGame.segment_definitions[payload.index].image;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].image = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].image;
                }
            }
        }
    },
    setGameJSON: (state, gameJSON) => {
        state.gameJSON = gameJSON;
    },
    addNewGame: (state, game) => {
        state.gamesList.push(game)
    },
    removeGame: (state, id) => {
        state.gamesList.splice(game => game.id, 1);
    },
    clearWheels: (state, id) => {
        state.gamesList = [];
    },
    addNewAsset: (state, asset) => {
        state.assetList.push(asset)
    },
    addNewSharedAsset: (state, asset) => {
        state.sharedAssetList.push(asset)
    },
    removeAsset: (state, id) => {
        state.assetList.splice(asset => asset.id == id, 1);
    },
    clearAssetList: (state) => {
        state.assetList = [];
    },
    formatAssetList: (state, assetList) => {
        state.assetList = assetList;
        if (!state.assetList || state.assetList.length == 0) return;

        let uniqueAssets = Array.from(state.assetList.reduce((acc, current) => {
            acc.set(current.id, current);
            return acc;
        }, new Map()).values());
        
        for (let i = 0; i < uniqueAssets.length; i++) {
            let dateObj = new Date(uniqueAssets[i].dateCreated);
            let dateCreated = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear().toString().substr(2, 2);
            uniqueAssets[i].dateCreated = dateCreated;

            if (uniqueAssets[i].prefix.includes('/idle')) {
                uniqueAssets[i].assetType = 'Idle Animation';
            } else if (uniqueAssets[i].prefix.includes('/content')) {
                uniqueAssets[i].assetType = 'Content';
            } else if (uniqueAssets[i].prefix.includes('/winning-animation')) {
                uniqueAssets[i].assetType = 'Winning Animation';
            } else if (uniqueAssets[i].prefix.includes('/segment/54')) {
                uniqueAssets[i].assetType = '54 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/27')) {
                uniqueAssets[i].assetType = '27 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/18')) {
                uniqueAssets[i].assetType = '18 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/9')) {
                uniqueAssets[i].assetType = '9 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/3')) {
                uniqueAssets[i].assetType = '3 Segment';
            } else {
                uniqueAssets[i].id = null;
            }
        }
        uniqueAssets = uniqueAssets.filter(function (asset) {
            return asset.id != null;
        });

        state.assetList = uniqueAssets;
    },
    setStage: (state, stage) => {
        state.currentStage = stage;
    },
    setGameSegment: (state, newSegmentInfo) => {
        let segment = {
            id: newSegmentInfo.segmentId,
            value: newSegmentInfo.segment.name,
            group: newSegmentInfo.segment.group,
            bonusSegment: newSegmentInfo.segment.bonusSegment,
            image: newSegmentInfo.segment.image,
            animation: newSegmentInfo.segment.animation,
            definitionName: newSegmentInfo.segment.name,
            fillStyle: newSegmentInfo.segment.fillStyle,
            textFillStyle: "black",
        }
        if (newSegmentInfo.tier == 0) {
            state.selectedGame.segments[newSegmentInfo.segmentId] = segment;
        } else {
            state.selectedGame.tiers[newSegmentInfo.tier - 1].segments[newSegmentInfo.segmentId] = segment;
        }
    },
    setUploadContentError: (state, error) => {
        state.uploadContentError = error;
    },
    setDropdownsLoaded: (state, flag) => {
        state.dropdownsLoaded = flag;
    },
    setSelectedGameRule: (state, rule) => {
        state.selectedGame.gameRule = rule;
    },
    setSelectedGameSubRule: (state, rule) => {
        state.selectedGame.rules = rule;
    },
    setSelectedGameReplacementGroupSegment: (state, payload) => {
        if (payload.index != null && payload.replacementSegment != null && state.selectedGame.rules && state.selectedGame.rules.replacementGroup) {
            state.selectedGame.rules.replacementGroup[payload.index] = payload.replacementSegment;
        }
    },
    setSelectedGame: (state, selectedGame) => {
        if(selectedGame != null) {
            state.selectedGame = selectedGame;
        }
    },
    setCustomRuleContentId: (state, payload) => {
        state.customGameRuleContentId = payload.contentId;
    },
};