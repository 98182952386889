<template>
  <v-card color="elevation-0">
    <div id="loadingOverlay" :style="loadingOverlayStyle">
      <div class="loader" :style="spinnerStyle"></div>
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="6">
            <p class="mb-0 text-subtitle-2">
              Select Tier
            </p>
              
            <v-chip-group v-model="selectedTier">
              <v-chip
                v-for="(tier, index) in tiers"
                :key="`tier_${index}`"
                rounded
                small
                :class="tierChipClass(index)"
              >
                <span v-if="index == 0"> {{ $t('details.baseWheel') }}</span>
                <span v-else>Tier {{ index + 1 }}</span>
              </v-chip>
            </v-chip-group>

            <p class="mb-0 text-subtitle-2">
              {{ $t("create.selected_segment") }}
            </p>

            <v-chip-group
              v-model="selectedSegment"
              mandatory
              data-test="segment-chips"
              column
            >
              <v-chip
                v-for="(segment, index) in getSegments"
                :key="`${segment.group}+_${index}`"
                :value="segment"
                :class="
                  segment.id == selectedSegment.id
                    ? 'mx-2 white--text black'
                    : 'mx-2 black--text white'
                "
                :outlined="segment != selectedSegment"
                rounded
                small
                @change="changeSelectedSegment(index)"
              >
                {{ index + 1 }}
              </v-chip>
            </v-chip-group>

            <p class="mb-2 text-subtitle-2 font-weight-bold">
              {{ $t("create.assign_value") }}
            </p>

            <v-chip-group
              v-if="getSegmentDefinitions"
              data-test="values-chips"
              column
            >
              <v-chip
                v-for="(def, index) in getSegmentDefinitions"
                :key="`${def.name}+_${index}`"
                :value="def"
                :class="
                  selectedSegment && def.name == selectedSegment.definitionName
                    ? 'mx-2 white--text black'
                    : 'mx-2 black--text nonActiveChip'
                "
                :outlined="selectedSegment && def.name != selectedSegment.definitionName"
                rounded
                small
                @click="updateIndividualWheelSegment(def)"
              >
                {{ def.name || `Segment ${index +1}`}}
              </v-chip>
            </v-chip-group>
        </v-col>

        <v-col cols="6">
          <div class="custom_wheel_container my-4 mr-2" data-test="wheel-container">
            <digi-wheel ref="digiwheel" :showFlapper="false" :key="render"/>
          </div>
        </v-col>

      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DigiWheel from "./customWheel/DigiWheel.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DigiWheel,
  },
  data() {
    return {
      selectedSegment: {},
      render: 0,
      valid: false,
      selectedSegmentIndex: 0,
      loading: true
    };
  },
  created() {
    this.loadAssets();
    this.selectedTier = 0;
    if (this.getSegments) {
      this.selectedSegment = this.getSegments[0];
      this.updateSelectedTier(this.selectedTier)
    }
    this.$on("reloadWheel", this.loadWheel);
  },
  computed: {
    ...mapGetters([
      "getSelectedGame",
    ]),
    selectedTier: {
      get() {
        return this.getSelectedGame.selectedTier;
      },
      set(index) {
        if (index == undefined) return;
        this.updateSelectedTier(index);
        this.selectedSegment = this.getSegments[0];
        this.selectedSegmentIndex = 0;
        this.loadWheel();
      },
    },
    tiers() {
      return this.getSelectedGame.tiers.length + 1;
    },
    getSegments() {
      return this.selectedTier == 0 ? this.getSelectedGame.segments : this.getSelectedGame.tiers[this.selectedTier -1].segments;
    },
    getSegmentDefinitions() {
      return this.selectedTier == 0 ? this.getSelectedGame.segment_definitions : this.getSelectedGame.tiers[this.selectedTier -1].segment_definitions;
    },
    spinnerStyle() {
      return this.loading ? 'pointer-events: none' : '';
    },
    loadingOverlayStyle() {
      return this.loading ? '' : 'display: none';
    }

  },
  methods: {
    ...mapActions(["updateSegmentWithDefiniton", "updateOneSegment", "updateSelectedTier","getImageContentByIds", 
        "updateImageContentSrc", "getVideoContentById", "updateForegroundSrc", "updateBackgroundSrc", "updateWheelOverlaySrc", "updateBackgroundOverlaySrc","updateSegmentAnimation", "updateIdleAnimationSrc", "updateStartupAnimationSrc", "fillNullSegments"]),
    loadAssets() {
      this.loading = true;
      //Response handler logic in the component so can toggle 'this.render' to refresh the wheel when assets are loaded
      this.getImageContentByIds().then(response => {
        if (response && response.data.length > 0) {
          this.updateImageContentSrc(response.data);
        }
        this.fillNullSegments();
        this.loading = false;
        this.render = !this.render;
      }).catch( error => {
        console.log(error);
        this.fillNullSegments();
        this.loading = false;
        this.render = !this.render;
      });

      for (let i = 0; i < this.getSelectedGame.segment_definitions.length; i++) {
          if (this.getSelectedGame.segment_definitions[i].animation.contentId && this.getSelectedGame.segment_definitions[i].animation.src == null
                && this.getSelectedGame.segment_definitions[i].animation.mimeType.includes("video")) {
                  this.getVideoContentById(this.getSelectedGame.segment_definitions[i].animation.contentId).then(response => {
                    return new Blob([response.data]);       
                  }).then((blob) => {
                    const file = new File([blob], this.getSelectedGame.segment_definitions[i].animation.displayName, { type: this.getSelectedGame.segment_definitions[i].animation.mimeType });
                    let animation = Object.assign({}, this.getSelectedGame.segment_definitions[i].animation);
                    animation.src = URL.createObjectURL(file);
                    this.updateSegmentAnimation({
                      tier: 0,
                      index: i,
                      animation: animation,
                    });
                    this.render = !this.render;
                  }).catch( error => {
                    console.log(error);
                  });
          }
      }
      if (this.getSelectedGame.tiers.length > 0) {
        for (let i = 0; i < this.getSelectedGame.tiers.length; i++) {
            for (let s = 0; s < this.getSelectedGame.tiers[i].segment_definitions.length; s++) {
                if (this.getSelectedGame.tiers[i].segment_definitions[s].animation.contentId && this.getSelectedGame.tiers[i].segment_definitions[s].animation.src == null
                    && this.getSelectedGame.tiers[i].segment_definitions[s].animation.mimeType.includes("video")) {
                      this.getVideoContentById(this.getSelectedGame.tiers[i].segment_definitions[s].animation.contentId).then(response => {
                        return new Blob([response.data]);       
                      }).then((blob) => {
                        const file = new File([blob], this.getSelectedGame.tiers[i].segment_definitions[s].animation.displayName, { type: this.getSelectedGame.tiers[i].segment_definitions[s].animation.mimeType });
                        let animation = Object.assign({}, this.getSelectedGame.tiers[i].segment_definitions[s].animation);
                        animation.src = URL.createObjectURL(file);
                        this.updateSegmentAnimation({
                          tier: i + 1,
                          index: s,
                          animation: animation,
                        });
                        this.render = !this.render;
                      }).catch( error => {
                        console.log(error);
                      });
                }
            }
        }
      }

      if (this.getSelectedGame.foreground.asset.contentId && this.getSelectedGame.foreground.asset.src == null && this.getSelectedGame.foreground.asset.mimeType.includes("video")) {
        this.getVideoContentById(this.getSelectedGame.foreground.asset.contentId).then(response => {
          return new Blob([response.data]);       
        }).then((blob) => {
          const file = new File([blob], this.getSelectedGame.foreground.asset.fileName, { type: this.getSelectedGame.foreground.asset.mimeType });
          this.updateForegroundSrc({
            tier: 0,
            src: URL.createObjectURL(file),
          });
          this.render = !this.render;
        }).catch( error => {
          console.log(error);
        });
      }
      if (this.getSelectedGame.background.asset.contentId && this.getSelectedGame.background.asset.src == null && this.getSelectedGame.background.asset.mimeType.includes("video")) {
        this.getVideoContentById(this.getSelectedGame.background.asset.contentId).then(response => {
          return new Blob([response.data]);       
        }).then((blob) => {
          const file = new File([blob], this.getSelectedGame.background.asset.fileName, { type: this.getSelectedGame.background.asset.mimeType });
          this.updateBackgroundSrc({
            tier: 0,
            src: URL.createObjectURL(file),
          });
          this.render = !this.render;
        }).catch( error => {
          console.log(error);
        });
      }
      if (this.getSelectedGame.wheelOverlay.asset.contentId && this.getSelectedGame.wheelOverlay.asset.src == null && this.getSelectedGame.wheelOverlay.asset.mimeType.includes("video")) {
        this.getVideoContentById(this.getSelectedGame.wheelOverlay.asset.contentId).then(response => {
          return new Blob([response.data]);       
        }).then((blob) => {
          const file = new File([blob], this.getSelectedGame.wheelOverlay.asset.fileName, { type: this.getSelectedGame.wheelOverlay.asset.mimeType });
          this.updateWheelOverlaySrc({
            tier: 0,
            src: URL.createObjectURL(file),
          });
          this.render = !this.render;
        }).catch( error => {
          console.log(error);
        });
      }
      if (this.getSelectedGame.backgroundOverlay.asset.contentId && this.getSelectedGame.backgroundOverlay.asset.src == null && this.getSelectedGame.backgroundOverlay.asset.mimeType.includes("video")) {
        this.getVideoContentById(this.getSelectedGame.backgroundOverlay.asset.contentId).then(response => {
          return new Blob([response.data]);       
        }).then((blob) => {
          const file = new File([blob], this.getSelectedGame.backgroundOverlay.asset.fileName, { type: this.getSelectedGame.backgroundOverlay.asset.mimeType });
          this.updateBackgroundOverlaySrc({
            tier: 0,
            src: URL.createObjectURL(file),
          });
          this.render = !this.render;
        }).catch( error => {
          console.log(error);
        });
      }

      if (this.getSelectedGame.tiers.length > 0) {
        for (let i = 0; i < this.getSelectedGame.tiers.length; i++) {
          if (this.getSelectedGame.tiers[i].foreground.asset.contentId && this.getSelectedGame.tiers[i].foreground.asset.src == null && this.getSelectedGame.tiers[i].foreground.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.tiers[i].foreground.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.tiers[i].foreground.asset.fileName, { type: this.getSelectedGame.tiers[i].foreground.asset.mimeType });
              this.updateForegroundSrc({
                tier: i + 1,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }
          if (this.getSelectedGame.tiers[i].background.asset.contentId && this.getSelectedGame.tiers[i].background.asset.src == null && this.getSelectedGame.tiers[i].background.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.tiers[i].background.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.tiers[i].background.asset.fileName, { type: this.getSelectedGame.tiers[i].background.asset.mimeType });
              this.updateBackgroundSrc({
                tier: i + 1,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }
          if (this.getSelectedGame.tiers[i].wheelOverlay.asset.contentId && this.getSelectedGame.tiers[i].wheelOverlay.asset.src == null && this.getSelectedGame.tiers[i].wheelOverlay.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.tiers[i].wheelOverlay.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.tiers[i].wheelOverlay.asset.fileName, { type: this.getSelectedGame.tiers[i].wheelOverlay.asset.mimeType });
              this.updateWheelOverlaySrc({
                tier: 0,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }
          if (this.getSelectedGame.tiers[i].backgroundOverlay.asset.contentId && this.getSelectedGame.tiers[i].backgroundOverlay.asset.src == null && this.getSelectedGame.tiers[i].backgroundOverlay.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.tiers[i].backgroundOverlay.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.tiers[i].backgroundOverlay.asset.fileName, { type: this.getSelectedGame.tiers[i].backgroundOverlay.asset.mimeType });
              this.updateBackgroundOverlaySrc({
                tier: 0,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }
        }
      }

      if (this.getSelectedGame.idle.asset.contentId && this.getSelectedGame.idle.asset.src == null) {
        this.getVideoContentById(this.getSelectedGame.idle.asset.contentId).then(response => {
          return new Blob([response.data]);       
        }).then((blob) => {
          const file = new File([blob], this.getSelectedGame.idle.asset.displayName, { type: this.getSelectedGame.idle.asset.mimeType });
          this.updateIdleAnimationSrc(URL.createObjectURL(file));
        }).catch( error => {
          console.log(error);
        });
      }

      if (this.getSelectedGame.animation.startupAsset && this.getSelectedGame.animation.startupAsset.contentId && this.getSelectedGame.animation.startupAsset.src == null) {
        this.getVideoContentById(this.getSelectedGame.animation.startupAsset.contentId).then(response => {
          return new Blob([response.data]);       
        }).then((blob) => {
          const file = new File([blob], this.getSelectedGame.animation.startupAsset.displayName, { type: this.getSelectedGame.animation.startupAsset.mimeType });
          this.updateStartupAnimationSrc(URL.createObjectURL(file));
        }).catch( error => {
          console.log(error);
        });
      }

    },
    changeSelectedSegment(index) {
      this.selectedSegmentIndex = index;
      this.$refs.digiwheel.drawWheel(true, index);
    },
    tierChipClass(index) {
      return index == this.selectedTier ? 'primary' : ''
    },
    updateIndividualWheelSegment(segment) {
      if (segment.name == this.selectedSegment.text) return;
      this.updateOneSegment({
        segment: segment,
        segmentId: this.selectedSegment.id,
        tier: this.selectedTier
      });

      if (this.selectedSegmentIndex < this.getSegments.length - 1) {
        this.selectedSegmentIndex++;
        this.selectedSegment = this.getSegments[this.selectedSegmentIndex];
      }
      this.$refs.digiwheel.drawWheel(true, this.selectedSegmentIndex);
    },
    loadWheel() {
      this.render = !this.render;
    },
  },
};
</script>

<style scoped>
.custom_wheel_container {
  width: 35vw;
  height: 35vw;
  border: none;
  overflow: hidden;
}

.nonActiveChip {
  border: 1px solid black;
  border-radius: 1em;
}
#loadingOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
