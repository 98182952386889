<template>
    <div class="container layer">
        
        <!-- 1 Background-->
        <div v-if="currentStageDesign.background.asset.src" class="backgroundAsset layer" :style="backgroundRotation">
            <img v-if="currentStageDesign.background.asset.mimeType.includes('image')" :src="currentStageDesign.background.asset.src" :style="backgroundSize" class="image_center">
            <video v-if="currentStageDesign.background.asset.mimeType.includes('video')" autoplay loop :style="backgroundSize" class="image_center">
                <source :src="currentStageDesign.background.asset.src"/>
            </video>
        </div>

        <!-- 2 Background Overlay-->
        <div v-if="currentStageDesign.backgroundOverlay.asset.src" class="backgroundOverlay layer" :style="backgroundOverlayRotation">
            <img v-if="currentStageDesign.backgroundOverlay.asset.mimeType.includes('image')" :src="currentStageDesign.backgroundOverlay.asset.src" :style="backgroundOverlaySize" class="image_center">
            <video v-if="currentStageDesign.backgroundOverlay.asset.mimeType.includes('video')" autoplay loop :style="backgroundOverlaySize" class="image_center">
                <source :src="currentStageDesign.backgroundOverlay.asset.src"/>
            </video>
        </div>

        <!-- 3 -->
        <div class="flapperOverlay layer">
            <canvas id="canvas" ref="wheelCanvas" class="wheelCanvas layer" v-if="showWheel"></canvas>
            <div class="flapperDiv" :style="!showFlapper ? 'display: none' : ''">
                <img class="flapper" width="60px" :src="require('@/assets/Flapper.png')"/>
            </div>
        </div>

        <!-- 4 Wheel Overlay-->
        <div v-if="currentStageDesign.wheelOverlay.asset.src" class="wheelOverlay layer" :style="wheeloverlayRotation">
            <img v-if="currentStageDesign.wheelOverlay.asset.mimeType.includes('image')" :src="currentStageDesign.wheelOverlay.asset.src" :style="wheelOverlaySize" class="image_center">
            <video v-if="currentStageDesign.wheelOverlay.asset.mimeType.includes('video')" autoplay loop :style="wheelOverlaySize" class="image_center">
                <source :src="currentStageDesign.wheelOverlay.asset.src"/>
            </video>
        </div>

        <!-- 5 Foreground-->
        <div v-if="currentStageDesign.foreground.asset.src" class="foregroundAsset layer" :style="foregroundRotation">
            <img v-if="currentStageDesign.foreground.asset.mimeType.includes('image')" :src="foregroundSrc" :style="foregroundSize" class="image_center">
            <video v-if="currentStageDesign.foreground.asset.mimeType.includes('video')" autoplay loop :style="foregroundSize" class="image_center">
                <source :src="currentStageDesign.foreground.asset.src"/>
            </video>
        </div>

        <!-- 6 -->
        <div class="animationLayer layer" v-show="showAnimation">
			<video ref="animation" autoplay :key="animationAsset" @ended="endAnimation" class="center video" style="height: 100%">
				<source :src="animationAsset">
			</video>
        </div>
    </div>
</template>

<script>
import { Winwheel } from "@/assets/js/Winwheel";
import DisplayLayer from "./DisplayLayer.vue";
import { mapGetters, mapActions } from 'vuex';
import { prepareNextWheel, deepCopyFunction } from "../../utils/RulesEngine";

export default {
    components: {
        DisplayLayer
    },
    data() {
        return {
            turn: 0,
            currentPosition: 0,
            previousPosition: 0,
            validSpin: false,
            winningSegment: null,
            idleTimer: null,
            showWheel: true,
            backgroundAsset: null,
            backgroundAssetSize: null,
            animationAsset: null,
            loopAnimation: false,
            showAnimation: false,
            showIdleAnimation: false,
            wheel: null,
            selectedGameCopy: null
        }
    },
    props: {
        showFlapper: {
            type: Boolean,
            default: false,
        },
        showWinningAnimation: {
            type: Boolean,
            default: false,
        },
        rulesTempWheelEnabled: {
            type: Boolean,
            default: false,
        },
        rulesPreviewEnabled: {
            type: Boolean,
            default: false,
        } 
        
    },
    mounted() {
        this.setupCanvas();
        this.drawWheel(false, 0);
        this.selectedGameCopy = deepCopyFunction(this.getSelectedGame);
    },
    computed: {
        ...mapGetters(["getSelectedGame"]),
        backgroundSize(){
            return `height: ${this.currentStageDesign.background.assetSize}%;`;
        },
        foregroundSize(){
            return `height: ${this.currentStageDesign.foreground.assetSize}%;`;
        },
        backgroundOverlaySize(){
            return `height: ${this.currentStageDesign.backgroundOverlay.assetSize}%;`;
        },
        wheelOverlaySize(){
            return `height: ${this.currentStageDesign.wheelOverlay.assetSize}%;`;
        },
        currentStageDesign() {
            if (this.rulesPreviewEnabled && this.selectedGameCopy != null) {
                return this.selectedGameCopy.selectedTier == 0 ? this.selectedGameCopy : this.selectedGameCopy.tiers[this.selectedGameCopy.selectedTier - 1];
            }
            return this.getSelectedGame.selectedTier == 0 ? this.getSelectedGame : this.getSelectedGame.tiers[this.getSelectedGame.selectedTier - 1];
        },
        idleAnimation() {
            return this.getSelectedGame.idle;
        },
        foregroundSrc() {
            return this.currentStageDesign.foreground.asset.src;
        },    
        backgroundRotation() {
            this.turn;
            return this.rotationCheck(this.currentStageDesign.background.rotation)
        },
        backgroundOverlayRotation() {
            this.turn;
            return this.rotationCheck(this.currentStageDesign.backgroundOverlay.rotation)
        },
        wheeloverlayRotation() {
            this.turn;
            return this.rotationCheck(this.currentStageDesign.wheelOverlay.rotation)
        },
        foregroundRotation() {
            this.turn;
            return this.rotationCheck(this.currentStageDesign.foreground.rotation)
        },
        
    },
    methods: {
        ...mapActions([
            "updateSelectedGame"
        ]),

        rotationCheck(rotation){
            if(!rotation){
                return { transform: "rotate(" + this.turn + "deg)" };
            } else if(rotation == 'None'){
                return { transform: "rotate(" + this.turn + "deg)" };
            }
            else if(rotation == 'Counter'){
                return {};
            } else if(rotation == 'Double Speed'){
                return { transform: "rotate(" + this.turn*2 + "deg)" };
            } else if(rotation == 'Reverse'){
                let reverseTurn = 1 - this.turn;
                return { transform: "rotate(" + reverseTurn + "deg)" };
            } else if(rotation == 'Random Speed'){
                let reverseTurn = 1 - this.turn;
                return { transform: "rotate(" + reverseTurn + "deg)" };
            } else if(rotation == 'Random Reverse'){
                let newRotation = this.turn;
                return { transform: "rotate(" + newRotation + "deg)" };
            }
        },
        playIdleAnimation() {
            if (this.idleAnimation.asset.src) {
                this.showAnimation = true;
                this.showIdleAnimation = true;
                this.animationAsset = this.idleAnimation.asset.src;
            }
        },
        calculateTotalDegrees(index, direction, startingPosition, rotationAmount){
            if (rotationAmount) {
                return direction == 1 ? rotationAmount  : -rotationAmount;
            }
            return direction == 1 ? (365.625 - ((index) * 5.625)) + 360 - startingPosition :
            ((index) * 5.625) + 360 + startingPosition;
        },
        spinWheel(direction, rotationAmount) {
            this.showAnimation = false;
            this.showIdleAnimation = false;
            let degreesRotated = 0;
            let currentSpeed = 0;
            let startingPosition = this.currentPosition;
            let currentSegment = Math.floor(Math.random() * 64) + 1;
            const element = document.getElementById('canvas');
            let self = this;
            self.maxSpeed = 3;  // Max degrees per frame
            self.acceleration = 0.1; // Predefined initial acceleration
            self.totalDegrees = this.calculateTotalDegrees(currentSegment, direction, startingPosition, rotationAmount);
            if (self.totalDegrees < 300 && !rotationAmount) {
                self.totalDegrees += 360;
            }
            // Calculate time to reach maxSpeed and distances for acceleration and deceleration
            let timeToMaxSpeed = self.maxSpeed / self.acceleration;
            let degreesForAcceleration = (self.maxSpeed * timeToMaxSpeed) / 2;
            let degreesForDeceleration = self.totalDegrees - degreesForAcceleration;

            // Calculate deceleration factor so that the wheel will stop at totalDegrees
            self.deceleration = (self.maxSpeed * self.maxSpeed) / (2 * degreesForDeceleration);

            function rotateStep() {
                // Determine phase: Acceleration or Deceleration
                let halfway = degreesForAcceleration;

                // Acceleration phase
                if (Math.abs(degreesRotated) < halfway) {
                    currentSpeed += self.acceleration;
                    if (currentSpeed > self.maxSpeed) {
                        currentSpeed = self.maxSpeed;
                    }
                } 
                // Deceleration phase
                else {
                    currentSpeed -= self.deceleration;
                    if (currentSpeed < 0) {
                        currentSpeed = 0;
                        if (Math.abs(degreesRotated) < Math.abs(self.totalDegrees)) {
                            degreesRotated = self.totalDegrees;
                        }
                    }
                }

                // Calculate the next degrees rotated based on direction
                let nextDegrees = degreesRotated + (currentSpeed * direction);
                
                degreesRotated = nextDegrees;
                
                // Rotate the element
                element.style.transform = `rotate(${degreesRotated + startingPosition}deg)`;
                self.setTurn(self.getRotationDegrees(document.getElementById("canvas")) % 360);

                // Check if we've reached the total rotation
                if (Math.abs(degreesRotated) < Math.abs(self.totalDegrees)) {
                if(currentSpeed == 0){
                    if((Math.round((degreesRotated) * 1000) / 1000) == self.totalDegrees){
                    self.endSpin();
                    }
                }
                requestAnimationFrame(rotateStep);
                } else {
                    self.endSpin();
                }
            }

            // Start the rotation
            rotateStep();
        },
        async endSpin(){
            this.$emit('wheelStopped', true);
            this.currentPosition = this.getRotationDegrees(document.getElementById("canvas")) % 360;
            let numOfSegments = this.currentStageDesign.segments.length;
            let winningSegment = Math.floor(numOfSegments * ( (360 - this.currentPosition) / 360));
            if (winningSegment == numOfSegments) {
                winningSegment--;
            }
            if (this.rulesPreviewEnabled) {
                this.selectedGameCopy = await prepareNextWheel(this.selectedGameCopy, this.selectedGameCopy.segments[winningSegment], false); 
            }
            setTimeout(() => {
                this.drawWheel(true, winningSegment);
            }, 800);
            setTimeout(() => {
                if (this.showWinningAnimation && this.currentStageDesign.segments[winningSegment].animation) {
                    this.showAnimation = true;
                    this.animationAsset = this.currentStageDesign.segments[winningSegment].animation.src;
                }
            }, 1600);
            setTimeout(this.drawWheel, 3000);
            console.log(this.currentStageDesign)
        },
        getRotationDegrees(obj) {
          const style = window.getComputedStyle(obj, null);
          const transformString = style.getPropertyValue("transform");
          if (transformString === 'none') return 0;
          const values = transformString.split('(')[1].split(')')[0].split(',');
          const a = values[0];
          const b = values[1];
          const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
          return angle < 0 ? angle + 360 : angle;
        },
        endAnimation() {
            if (this.showIdleAnimation) {
                this.$refs.animation.currentTime = 0;
                this.$refs.animation.play();
            } else {
                this.showAnimation = false;
                this.animationAsset = null;
            }
        },
        setupCanvas() {
            const { $refs } = this;
            if ($refs.wheelCanvas) {
                $refs.wheelCanvas.width = 1024;
                $refs.wheelCanvas.height = 1024;
            }
        },
        setTurn(turn) {
            this.turn = turn;
        },
        async drawWheel(showWinningSegment, winningSegment) {
            this.wheel = new Winwheel({
                numSegments: this.currentStageDesign.segments.length,
                textFillStyle: this.currentStageDesign.textFillStyle,
                textFontFamily: this.currentStageDesign.textFontFamily,
                textFontSize: this.currentStageDesign.textFontSize,
                textMargin: this.currentStageDesign.textMargin,
                textFontWeight: this.currentStageDesign.textFontWeight,
                textAlignment: this.currentStageDesign.textAlignment,
                textDirection: this.currentStageDesign.textDirection,
                textOrientation: this.currentStageDesign.textOrientation,
                strokeStyle: this.currentStageDesign.strokeStyle,
                drawMode: this.currentStageDesign.drawMode,
                drawText: this.currentStageDesign.drawText,
                lineWidth: this.currentStageDesign.lineWidth,
                outerRadius: this.currentStageDesign.outerRadius,
                innerRadius: this.currentStageDesign.innerRadius,
                pointerGuide: { display: false, strokeStyle: "black" },
                segments: this.currentStageDesign.segments,
                winningSegment: showWinningSegment ? winningSegment : null,
                shieldImageTopPadding: this.currentStageDesign.shieldImageTopPadding ? this.currentStageDesign.shieldImageTopPadding : null,
                shieldImageHeight: this.currentStageDesign.shieldImageHeight ? this.currentStageDesign.shieldImageHeight : null,
                shieldImageWidth: this.currentStageDesign.shieldImageWidth ? this.currentStageDesign.shieldImageWidth : null,
                bannerImageTopPadding: this.currentStageDesign.bannerImageTopPadding ? this.currentStageDesign.bannerImageTopPadding : null,
                bannerImageHeight: this.currentStageDesign.bannerImageHeight ? this.currentStageDesign.bannerImageHeight : null,
                bannerImageWidth: this.currentStageDesign.bannerImageWidth ? this.currentStageDesign.bannerImageWidth : null,
                backgroundImageTopPadding: this.currentStageDesign.backgroundImageTopPadding ? this.currentStageDesign.backgroundImageTopPadding : null,
                backgroundImageHeight: this.currentStageDesign.backgroundImageHeight ? this.currentStageDesign.backgroundImageHeight : null,
                backgroundImageWidth: this.currentStageDesign.backgroundImageWidth ? this.currentStageDesign.backgroundImageWidth : null,
            });
        }
    }
}

</script>
<style type="text/css" scoped>
.container {
    background-color: black;
    position: relative !important;
}
.center {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.image_center {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

.backgroundAsset {
    z-index: 1;
}
.backgroundOverlay {
    z-index: 2;
}

.wheelCanvas {
    z-index: 3;
}

.wheelOverlay {
    z-index: 5;
}
.flapperDiv {
    position: absolute;
    z-index: 13;
    left: 50%;
    max-width: min-content;
    top: 2%;
  }

.foregroundAsset {
    z-index: 6;
}

.animationLayer {
    z-index: 7;
}

.layer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.flapperOverlay {
    z-index: 4;
}
.flapper {
    transform: translate(-50%, -50%);
}
.video {
    width: -webkit-fill-available;
}
</style>