export default {
    app: {
        name: "Configurator",
        version: "1.0",
        content_service: "https://server.digiwheelwizard.com/",
        // prod -> content_service: "https://server.digiwheelwizard.com/",
        // test -> content_service: "https://test.server.digiwheelwizard.com/"
        // local -> content_service: "http://localhost:8083/",
        contentServiceIP: "server.digiwheelwizard.com", //This must be set to the domain section of the 'content_service' URL
        content_controller: "content",
        authentication_controller: "auth",
    },
    action: {
        continue: "Continue",
        submit: "Submit",
        cancel: "Cancel",
        goback: "Go Back",
        search: "Search",
        draft: "Save as draft",
        save: "Update game",
        delete: "Delete",
        randomspin: "Random Spin",
        generateGame: "Generate Game",
        confirmGame: "Confirm Game",
        rotateLeft: "Rotate Left",
        rotateRight: "Rotate Right",
        add: "Add",
        upload: "Upload",
        buildGame: "Build Game",
        downloadGame: "Download",
        goToRules: "Go to Game Rules",
    },
    login: {
        title: "Sign in with your Digiwheel account",
        username: "Enter your username",
        password: "Enter your password",
        enter: "Sign in",
        reset: "Trouble signing in?",
        error: "Incorrect password. Please try again."
    },
    games: {
        title: "Custom Wheels",
        subtitle: "View, duplicate, edit or delete existing wheels. Or create a new wheel game."
    },
    assets: {
        title: "Asset Library",
        subtitle: "View or delete your assets."
    },
    rules: {
        title: "Game Rules",
        subtitle: "Assign rules to the created games.",
        selectGame: "Select a game",
        games: "Games",
        selectRule: "Select a rule",
        selectSubRule: "Select a sub rule",
        rules: "Rules",
        removeWinningSegmentHelper: "This rule controls what happens to the winning segment after it is selected.",
        removeWinningGroupHelper: "This rule removes all segments belonging to the same group as the winning segment.",
        shuffleHelper: "This rule shuffles the segments on the wheel.",
        customHelper: "This rule allows for custom game logic.",
        removeWinningSegmentSubRules: {
            none: "No sub rule will be applied to the game when this option is selected.",
            random: "The winning segment will be replaced by a randomly selected segment from the wheel.",
            newSegment: "The winning segment will be replaced by a new segment defined in the Segment Definition section below.",
            newGroup: "The winning segment will be replaced by a random segment from the specified group in the Group Definition section below.",
            next: "The winning segment will be replaced by the next segment in clockwise order.",
            previous: "The winning segment will be replaced by the previous segment in clockwise order.",
            default: "The winning segment will be removed with no replacement."
        },
        removeWinningGroupSubRules: {
            none: "No sub rule will be applied to the game when this option is selected.",
            newSegment: "Replace all segments in the winning group with a specified segment.",
            replacementGroupSequential: "Replace all segments in the winning group with segments from another group sequentially.",
            replacementGroupRandom: "Replace all segments in the winning group with segments from another group randomly.",
            replace: "Replace all segments in the winning group with random segments from the rest of the wheel.",
            default: "Removes all segments in the winning group with no replacement."
        },
        apply: "Apply Rules",
        selectedRule: "Selected Rule",
        selectedSubRule: "Selected Sub Rule",
        backToSetup: "Back to Rules",
        confirm: "Confirm and Save",
        newSegmentHeading: "Segment Definition",
        newGroupHeading: "Group Definition",
        type: "Type",
        impactedSegments: "Impacted Segments",
        shuffleIncludeHelper: "You have chosen to include specific segments in the shuffle. Only the segments you select will be shuffled. All other segments will remain fixed in their current positions.",
        shuffleExcludeHelper: "You have chosen to exclude (freeze) specific segments from the shuffle. The segments you select will remain in place, and all other segments will be shuffled.",
        shuffleImpactedSegmentsIncludeListHints : {
            default: "All segments on the wheel will be shuffled by default.",
            winningGroup: "Only the segments belonging to the same group as the winning segment will be shuffled. Other segments will remain fixed in place.",
            selectedIds: "Specify individual segments (by ID) to be included in the shuffle. Only these segments will be shuffled, while others remain fixed.",
            selectedValues: "Segments with the specified values will be shuffled. Other segments will remain in place.",
            selectedGroups: "Shuffle only the segments that belong to the selected group. Segments outside the group will remain fixed."
        },
        shuffleImpactedSegmentsExcludeListHints : {
            default: "No segments will be excluded from the shuffle. All segments will be shuffled.",
            winningSegment: "The winning segment will remain in place and will not be shuffled. All other segments will be shuffled.",
            winningGroup: "All segments in the winning group will remain in place and will not be shuffled. All other segments will be shuffled.",
            selectedIds: "The segments with the specified IDs will remain in place and will not be shuffled. All other segments will be shuffled.",
            selectedValues: "Segments with the specified values will remain in place and will not be shuffled. All other segments will be shuffled.",
            selectedGroups: "All segments in the predefined group will remain in place and will not be shuffled. All other segments will be shuffled."
        },
        groupName: "Replacement group name",
        ruleTips: "Rule Tips",
        save: "Save",
        validGameJson: "There needs to be a valid  GameJson returned at the end of the round.",
        thingsToRemember: "Things to remember",
        sampleCode: "Sample Code"
    },
    create: {
        assign_value: "Assign value",
        details: "Game Details",
        segments: "Define Segments",
        selected_segment: "Selected segment",
        wheel: "Create Wheel",
        level: "Additional Level",
        verify: "Verify"
    },
    details: {
        wheel_name: "Wheel Name",
        wheel_name_placeholder: "Give your wheel a name",
        wheel_description: "Wheel Description",
        wheel_description_placeholder: "Add a wheel description",
        wheel_tiers: "Number of Tiers",
        name_required:"Game Name is required",
        idle_animation: "Idle Animation",
        idle_animation_timer: "Idle Animation Timer",
        idle_animation_duration: "Idle Animation Duration",
        startup_animation: "Start-up Animation",
        startup_animation_timer: "Start-up Animation Timer",
        startup_animation_duration: "Start-up Animation Duration",
        startup_animation_loop: "Loop Animation",
        segment_options: "Number of Segments",
        make_selection: "Make a selection",
        selection_required: "Selection is required",
        upload_files_title: "Upload files",
        upload_files_subtitle: "Start-up animation, idle animation, centre frame",
        upload_files_placeholder: "Drag and drop, or browse your files",
        upload_animation: "Upload Animation",
        upload_idle_subtitle: "Video to appear when wheel is inactive(webm and mp4)",
        upload_idle_timer_subtitle: "Duration before Idle Animation is displayed (in seconds)",
        upload_idle_duration_subtitle: "Duration that Idle Animation is displayed for (in seconds)",
        upload_startup_subtitle: "Animation played before the wheel is loaded(webm and mp4)",
        upload_startup_timer_subtitle: "Duration before Start-up Animation is displayed (in seconds)",
        upload_startup_duration_subtitle: "Duration that Start-up Animation is displayed for (in seconds)",
        upload_content: "Upload Content",
        upload_content_subtitle: "Video or Image that will be available for background, background overlay, wheel overlay and foreground selection",
        upload_background_content: "Upload Background Content",
        upload_background_content_subtitle: "Video or Image that will be available for background and background overlay selection",
        select_foreground_content: "Foreground Content",
        select_foreground_content_subtitle: "Displayed in front of the wheel overlay and behind the animation layer. Primarily used to display a logo on the wheel.",
        select_foreground_content_size: "Foreground Size",
        select_foreground_content_size_subtitle: "Size as a percentage of the wheel screen",
        select_foreground_rotation: "Foreground Rotation",
        select_foreground_rotation_subtitle: "Foreground Rotation",
        select_background_content: "Background Content",
        select_background_content_subtitle: "Sits behind all other content. It will remain upright even as the wheel spins.",
        select_background_content_size: "Background Size",
        select_background_rotation: "Background Rotation",
        select_background_rotation_subtitle: "Background Rotation",
        select_wheelOverlay_content: "Wheel Overlay Content",
        select_wheelOverlay_content_subtitle: "Displayed in front of the wheel segments and behind the foreground. Primarily used to add visual flourish to a wheel.",
        select_wheelOverlay_content_size: "Wheel Overlay Size",
        select_wheelOverlay_content_size_subtitle: "Size as a percentage of the wheel screen",
        select_wheelOverlay_rotation: "Wheel Overlay Rotation",
        select_wheelOverlay_rotation_subtitle: "Wheel Overlay Rotation",
        select_backgroundOverlay_content: "Background Overlay Content",
        select_backgroundOverlay_content_subtitle: "Displayed behind the wheel segments and in front of the background. Primarily used to frame the background.",
        select_backgroundOverlay_content_size: "Background Overlay Size",
        select_backgroundOverlay_rotation: "Background Overlay Rotation",
        select_backgroundOverlay_rotation_subtitle: "Background Overlay Rotation",
        upload_segment_content: "Upload Segment Images",
        upload_segment_subtitle: "Individual segment images",
        upload_segment_animations: "Upload Winning Animations",
        upload_segment_animations_subtitle: "Winning animations for segments",
        segment_animation_size: "Winning Animation Size",
        segment_animation_size_subtitle: "Size as a percentage of the wheel screen",
        segment_animation_duration: "Winning Animation Duration",
        segment_animation_duration_subtitle: "Duration that segment animation is displayed for (in seconds)",
        segment_animation_delay: "Winning Animation Delay",
        segment_animation_delay_subtitle: "Delay before that segment animation is displayed (in seconds)",
        choose_file: "Choose file",
        characters_allowed: "Game name may only include alphanumeric characters, spaces, underscores, and hyphens",
        character_length: "Game name must be less than 20 characters",
        wheelName: "Wheel Name",
        numberOfTiers: "Number of Tiers",
        tierOneSegmentCount: "Tier 1 Segment Count",
        bonusSegmentCount: "Bonus Segment Count",
        selectTier: "Select Tier",
        baseWheel: "Base Wheel",
        addNew: "Add New",
        addTag: "Add Tag",
        confirmDelete: "Are you sure you want to delete? This will also delete any associated assets.",
        newFilter: "New Filter",
        newAsset: "New Asset",
        segment_value_subtitle: "In case there is a Kiosk CMS being used for this game, make sure to use numeric value to specify cash, numerical value followed by P to specify Points and numeric value followed by Pr to signal that it is a Promotional Value.",
        segment_groups_subtitle: "List of comma seperated groups. Example: 'Group1, Group2, Group3'",
        buildWheelHeader: "Download Wheel Game",
        buildWheelSubtitle: "Create your game to add it to the build queue. It will be available for download once the build process is complete.",
    },
    segment: {
        header: "Segment {index}",
        name: "Segment Name",
        number_of_segments: "Number of segments",
        name_required:"Name is required",
        name_length_error: 'Segment name must be less than 20 characters',
        upload_files_title: "Upload files",
        upload_files_subtitle: "Tier 1 segments, animations, centre content",
        upload_files_placeholder: "Drag and drop, or browse your files",
    }
};    
  