<template>
    <v-card color="elevation-0">
      <div id="loadingOverlay" :style="loadingOverlayStyle">
        <div class="loader" :style="spinnerStyle"></div>
      </div>
      <v-container fluid>
        <v-row  class="ml-6">
          <v-col cols="6" >
            <v-row class="">
              <v-col cols="5" class="">
                <label class="font-weight-bold">{{ $t('rules.selectedRule') }}:</label>
              </v-col>
              <v-col cols="6" class="ml-n8">
                <label class="">{{ getSelectedGame.gameRule }}</label>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="5" class="">
                <label class="font-weight-bold">{{ $t('rules.selectedSubRule') }}:</label>
              </v-col>
              <v-col cols="6" class="ml-n8">
                <label class="">{{ getSelectedGame.rules ? getSelectedGame.rules.type : "None" }}</label>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="mb-6 mt-2"></v-divider>
            </v-row>
            <p class="mb-0 text-subtitle-2">
              {{ $t('details.selectTier') }}
            </p>
            <v-chip-group v-model="selectedTier">
              <v-chip
                v-for="(tier, index) in tiers"
                :key="`tier_${index}`"
                rounded
                small
                :class="tierChipClass(index)"
              >
                <span v-if="index == 0"> {{ $t('details.baseWheel') }}</span>
                <span v-else>Tier {{ index + 1 }}</span>
              </v-chip>
            </v-chip-group>
            <v-row class="mb-2 mt-4 pl-3">
              <v-btn outlined rounded class="mr-4 black--text" @click="randomSpin" :style="disableSpinButtons">{{ $t('action.randomspin') }}</v-btn>
            </v-row>
            <v-row class="row mb-2 pl-3">
              <v-radio-group row v-model="spinDirection">
                  <v-radio label="Clockwise" value="clockwise"></v-radio>
                  <v-radio label="Counter Clockwise" value="counterClockwise"></v-radio>
                  <v-radio label="Alternate Direction" value="alternate"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row class="pl-3">
              <v-btn outlined rounded class="mr-4 black--text" @click="rotate(-1)" :style="disableSpinButtons"><v-icon>rotate-left</v-icon>{{ $t('action.rotateLeft') }} </v-btn>
              <v-btn outlined rounded class="mr-4 black--text" @click="rotate(1)" :style="disableSpinButtons">{{ $t('action.rotateRight') }}<v-icon>rotate-right</v-icon></v-btn>
            </v-row>
            <v-row class="d-flex mt-12 ml-1">
              <v-btn outlined class="mr-16 red white--text text-capitalize" @click="backToRules()" data-test="cancel-btn" >{{ $t('rules.backToSetup') }}</v-btn>
              <v-btn outlined class="mr-4 white--text primary text-capitalize" @click="confirm()" data-test="continue-btn">{{ $t('action.buildGame') }}</v-btn>
              <v-btn v-if="presignedUrl != null" outlined class="mr-4 white--text primary text-capitalize" @click="downloadGame()" data-test="download-btn">{{ $t('action.downloadGame') }}</v-btn>
            </v-row>
          </v-col>
          <v-col cols="6">
            <div class="custom_wheel_container" data-test="wheel-container">
              <digi-wheel ref="digiwheel" :rulesPreviewEnabled="true" :showFlapper="true" :showWinningAnimation="false" :key="render" @wheelStopped="handleWheelStopped"/>
            </div>
          </v-col>
  
        </v-row>
      </v-container>
    </v-card>
    </template>
    
    <script>
    import DigiWheel from "../components/customWheel/DigiWheel.vue";
    import { mapGetters, mapActions  } from "vuex";
    import { connectContentService } from "@/utils/sockets";

    export default {
      components: {
        DigiWheel
      },
      data(){
        return {
          render: 0,
          spinDirection: "clockwise",
          spinDirectionValue: 1,
          wheelSpinning: false,
          loading: true,
          presignedUrl: null
        }
      },
      async created() {
        this.selectedTier = 0;
        if (this.getSegments) {
          this.updateSelectedTier(this.selectedTier)
        }
        await this.loadAssets();
        this.loadWheel();
        connectContentService({
          handleGameZipBuilt: this.handleGameZipBuilt,
        });
        console.log(this.getSelectedGame)
      },
      computed: {
      ...mapGetters([
        "getSelectedGame",
      ]),

      selectedTier: {
        get() {
          return this.getSelectedGame.selectedTier;
        },
        set(index) {
          if (index == undefined) return;
          this.updateSelectedTier(index);
          this.selectedSegment = this.getSegments[0];
          this.loadWheel();
        },
      },
      tiers() {
        return this.getSelectedGame.tiers.length + 1;
      },
      getSegments() {
        return this.selectedTier == 0 ? this.getSelectedGame.segments : this.getSelectedGame.tiers[this.selectedTier -1].segments;
      },
      disableSpinButtons() {
        return this.wheelSpinning ? 'pointer-events: none; opacity: 0.6' : ''; 
      },
      spinnerStyle() {
        return this.loading ? 'pointer-events: none' : '';
      },
      loadingOverlayStyle() {
        return this.loading ? '' : 'display: none';
      }
    },
      methods: {
        ...mapActions(["getImageContentByIds", "updateImageContentSrc", "getVideoContentById", "updateForegroundSrc", "updateBackgroundSrc", "updateWheelOverlaySrc", 
          "updateBackgroundOverlaySrc","updateSegmentAnimation", "updateIdleAnimationSrc", "updateStartupAnimationSrc", "fillNullSegments", "saveGame", "saveGameRule",
          "uploadGameZip", "generateGameJSON", "uploadWheelGame", "updateWheelGamePresignedUrl", "getRulesImageContentByIds", "updateSelectedTier"]),
  
        async handleGameZipBuilt(message) {
          this.presignedUrl = message.presignedUrl;
          await this.updateWheelGamePresignedUrl(message);
        },
        backToRules() {
          this.$router.push("/gamerules");
        },
        async confirm() {
          this.loading = true;
          await this.generateGameJSON();
          await this.uploadWheelGame({'incompleteGame' : false});
          await this.saveGame('complete');
          await this.uploadGameZip();
          await this.saveGameRule();
          this.$toast.success("Your game has been added to the queue. Download will be available once it has been built.");
          this.loading = false;
        },
        async loadAssets() {
          this.loading = true;
          //Response handler logic in the component so can toggle 'this.render' to refresh the wheel when assets are loaded
          await this.getImageContentByIds().then(response => {
            if (response && response.data.length > 0) {
              this.updateImageContentSrc(response.data);
            }
            this.fillNullSegments();
            this.loading = false;
            this.render = !this.render;
          }).catch( error => {
            console.log(error);
            this.fillNullSegments();
            this.loading = false;
            this.render = !this.render;
          });

          for (let i = 0; i < this.getSelectedGame.segment_definitions.length; i++) {
            if (this.getSelectedGame.segment_definitions[i].animation.contentId && this.getSelectedGame.segment_definitions[i].animation.src == null
                  && this.getSelectedGame.segment_definitions[i].animation.mimeType.includes("video")) {
                    this.getVideoContentById(this.getSelectedGame.segment_definitions[i].animation.contentId).then(response => {
                      return new Blob([response.data]);       
                    }).then((blob) => {
                      const file = new File([blob], this.getSelectedGame.segment_definitions[i].animation.displayName, { type: this.getSelectedGame.segment_definitions[i].animation.mimeType });
                      let animation = Object.assign({}, this.getSelectedGame.segment_definitions[i].animation);
                      animation.src = URL.createObjectURL(file);
                      this.updateSegmentAnimation({
                        tier: 0,
                        index: i,
                        animation: animation,
                      });
                      this.render = !this.render;
                    }).catch( error => {
                      console.log(error);
                    });
            }
          }
          if (this.getSelectedGame.tiers.length > 0) {
            for (let i = 0; i < this.getSelectedGame.tiers.length; i++) {
                for (let s = 0; s < this.getSelectedGame.tiers[i].segment_definitions.length; s++) {
                    if (this.getSelectedGame.tiers[i].segment_definitions[s].animation.contentId && this.getSelectedGame.tiers[i].segment_definitions[s].animation.src == null
                        && this.getSelectedGame.tiers[i].segment_definitions[s].animation.mimeType.includes("video")) {
                          this.getVideoContentById(this.getSelectedGame.tiers[i].segment_definitions[s].animation.contentId).then(response => {
                            return new Blob([response.data]);       
                          }).then((blob) => {
                            const file = new File([blob], this.getSelectedGame.tiers[i].segment_definitions[s].animation.displayName, { type: this.getSelectedGame.tiers[i].segment_definitions[s].animation.mimeType });
                            let animation = Object.assign({}, this.getSelectedGame.tiers[i].segment_definitions[s].animation);
                            animation.src = URL.createObjectURL(file);
                            this.updateSegmentAnimation({
                              tier: i + 1,
                              index: s,
                              animation: animation,
                            });
                            this.render = !this.render;
                          }).catch( error => {
                            console.log(error);
                          });
                    }
                }
            }
          }

          if (this.getSelectedGame.foreground.asset.contentId && this.getSelectedGame.foreground.asset.src == null && this.getSelectedGame.foreground.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.foreground.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.foreground.asset.fileName, { type: this.getSelectedGame.foreground.asset.mimeType });
              this.updateForegroundSrc({
                tier: 0,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }
          if (this.getSelectedGame.background.asset.contentId && this.getSelectedGame.background.asset.src == null && this.getSelectedGame.background.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.background.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.background.asset.fileName, { type: this.getSelectedGame.background.asset.mimeType });
              this.updateBackgroundSrc({
                tier: 0,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }
          if (this.getSelectedGame.wheelOverlay.asset.contentId && this.getSelectedGame.wheelOverlay.asset.src == null && this.getSelectedGame.wheelOverlay.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.wheelOverlay.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.wheelOverlay.asset.fileName, { type: this.getSelectedGame.wheelOverlay.asset.mimeType });
              this.updateWheelOverlaySrc({
                tier: 0,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }
          if (this.getSelectedGame.backgroundOverlay.asset.contentId && this.getSelectedGame.backgroundOverlay.asset.src == null && this.getSelectedGame.backgroundOverlay.asset.mimeType.includes("video")) {
            this.getVideoContentById(this.getSelectedGame.backgroundOverlay.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.backgroundOverlay.asset.fileName, { type: this.getSelectedGame.backgroundOverlay.asset.mimeType });
              this.updateBackgroundOverlaySrc({
                tier: 0,
                src: URL.createObjectURL(file),
              });
              this.render = !this.render;
            }).catch( error => {
              console.log(error);
            });
          }

          if (this.getSelectedGame.tiers.length > 0) {
            for (let i = 0; i < this.getSelectedGame.tiers.length; i++) {
              if (this.getSelectedGame.tiers[i].foreground.asset.contentId && this.getSelectedGame.tiers[i].foreground.asset.src == null && this.getSelectedGame.tiers[i].foreground.asset.mimeType.includes("video")) {
                this.getVideoContentById(this.getSelectedGame.tiers[i].foreground.asset.contentId).then(response => {
                  return new Blob([response.data]);       
                }).then((blob) => {
                  const file = new File([blob], this.getSelectedGame.tiers[i].foreground.asset.fileName, { type: this.getSelectedGame.tiers[i].foreground.asset.mimeType });
                  this.updateForegroundSrc({
                    tier: i + 1,
                    src: URL.createObjectURL(file),
                  });
                  this.render = !this.render;
                }).catch( error => {
                  console.log(error);
                });
              }
              if (this.getSelectedGame.tiers[i].background.asset.contentId && this.getSelectedGame.tiers[i].background.asset.src == null && this.getSelectedGame.tiers[i].background.asset.mimeType.includes("video")) {
                this.getVideoContentById(this.getSelectedGame.tiers[i].background.asset.contentId).then(response => {
                  return new Blob([response.data]);       
                }).then((blob) => {
                  const file = new File([blob], this.getSelectedGame.tiers[i].background.asset.fileName, { type: this.getSelectedGame.tiers[i].background.asset.mimeType });
                  this.updateBackgroundSrc({
                    tier: i + 1,
                    src: URL.createObjectURL(file),
                  });
                  this.render = !this.render;
                }).catch( error => {
                  console.log(error);
                });
              }
              if (this.getSelectedGame.tiers[i].wheelOverlay.asset.contentId && this.getSelectedGame.tiers[i].wheelOverlay.asset.src == null && this.getSelectedGame.tiers[i].wheelOverlay.asset.mimeType.includes("video")) {
                this.getVideoContentById(this.getSelectedGame.tiers[i].wheelOverlay.asset.contentId).then(response => {
                  return new Blob([response.data]);       
                }).then((blob) => {
                  const file = new File([blob], this.getSelectedGame.tiers[i].wheelOverlay.asset.fileName, { type: this.getSelectedGame.tiers[i].wheelOverlay.asset.mimeType });
                  this.updateWheelOverlaySrc({
                    tier: 0,
                    src: URL.createObjectURL(file),
                  });
                  this.render = !this.render;
                }).catch( error => {
                  console.log(error);
                });
              }
              if (this.getSelectedGame.tiers[i].backgroundOverlay.asset.contentId && this.getSelectedGame.tiers[i].backgroundOverlay.asset.src == null && this.getSelectedGame.tiers[i].backgroundOverlay.asset.mimeType.includes("video")) {
                this.getVideoContentById(this.getSelectedGame.tiers[i].backgroundOverlay.asset.contentId).then(response => {
                  return new Blob([response.data]);       
                }).then((blob) => {
                  const file = new File([blob], this.getSelectedGame.tiers[i].backgroundOverlay.asset.fileName, { type: this.getSelectedGame.tiers[i].backgroundOverlay.asset.mimeType });
                  this.updateBackgroundOverlaySrc({
                    tier: 0,
                    src: URL.createObjectURL(file),
                  });
                  this.render = !this.render;
                }).catch( error => {
                  console.log(error);
                });
              }
            }
          }

          if (this.getSelectedGame.idle.asset.contentId && this.getSelectedGame.idle.asset.src == null) {
            this.getVideoContentById(this.getSelectedGame.idle.asset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.idle.asset.displayName, { type: this.getSelectedGame.idle.asset.mimeType });
              this.updateIdleAnimationSrc(URL.createObjectURL(file));
            }).catch( error => {
              console.log(error);
            });
          }

          if (this.getSelectedGame.animation.startupAsset && this.getSelectedGame.animation.startupAsset.contentId && this.getSelectedGame.animation.startupAsset.src == null) {
            this.getVideoContentById(this.getSelectedGame.animation.startupAsset.contentId).then(response => {
              return new Blob([response.data]);       
            }).then((blob) => {
              const file = new File([blob], this.getSelectedGame.animation.startupAsset.displayName, { type: this.getSelectedGame.animation.startupAsset.mimeType });
              this.updateStartupAnimationSrc(URL.createObjectURL(file));
            }).catch( error => {
              console.log(error);
            });
          }

          this.getRulesImageContentByIds().then(response => {
            if (response && response.data.length > 0) {
              this.updateImageContentSrc(response.data);
            }
            this.fillNullSegments();
            this.loading = false;
            this.render = !this.render;
          }).catch( error => {
            console.log(error);
            this.fillNullSegments();
            this.loading = false;
            this.render = !this.render;
          });
          if (this.getSelectedGame.rules && this.getSelectedGame.rules.replacementGroup) {
            for (let i = 0; i < this.getSelectedGame.rules.replacementGroup.length; i++) {
              if (this.getSelectedGame.rules.replacementGroup[i].animation && this.getSelectedGame.rules.replacementGroup[i].animation.contentId 
              && this.getSelectedGame.rules.replacementGroup[i].animation.src == null && this.getSelectedGame.rules.replacementGroup[i].animation.mimeType.includes("video")) {
                this.getVideoContentById(this.getSelectedGame.rules.replacementGroup[i].animation.contentId).then(response => {
                  return new Blob([response.data]);       
                }).then((blob) => {
                  const file = new File([blob], this.getSelectedGame.rules.replacementGroup[i].animation.displayName, { type: this.getSelectedGame.rules.replacementGroup[i].animation.mimeType });
                  let animation = Object.assign({}, this.getSelectedGame.rules.replacementGroup[i].animation);
                  animation.src = URL.createObjectURL(file);
                  this.updateSegmentAnimation({
                    tier: 0,
                    index: i,
                    animation: animation,
                  });
                  this.render = !this.render;
                }).catch( error => {
                  console.log(error);
                });
              }
            }
          }
          if (this.getSelectedGame.rules && this.getSelectedGame.rules.groups) {
            for (let group in this.getSelectedGame.rules.groups) {
              for (let i = 0; i < this.getSelectedGame.rules.groups[group].length; i++) {
                if (this.getSelectedGame.rules.groups[group][i].animation && this.getSelectedGame.rules.groups[group][i].animation.contentId && this.getSelectedGame.rules.groups[group][i].animation.src == null
                      && this.getSelectedGame.rules.groups[group][i].animation.mimeType && this.getSelectedGame.rules.groups[group][i].animation.mimeType.includes("video")) {
                  this.getVideoContentById(this.getSelectedGame.rules.groups[group][i].animation.contentId).then(response => {
                    return new Blob([response.data]);       
                  }).then((blob) => {
                    const file = new File([blob], this.getSelectedGame.rules.groups[group][i].animation.displayName, { type: this.getSelectedGame.rules.groups[group][i].animation.mimeType });
                    let animation = Object.assign({}, this.getSelectedGame.rules.groups[group][i].animation);
                    animation.src = URL.createObjectURL(file);
                    this.updateSegmentAnimation({
                      tier: 0,
                      index: i,
                      animation: animation,
                    });
                    this.render = !this.render;
                  }).catch( error => {
                    console.log(error);
                  });
                }
              }
            }
          }
        },
        randomSpin() {
          this.wheelSpinning = true;
          if (this.spinDirection == 'alternate') {
            this.spinDirectionValue = this.spinDirectionValue == 1 ? -1 : 1;
          } else {
            this.spinDirectionValue = this.spinDirection == 'clockwise' ? 1 : -1
          }
          this.$refs.digiwheel.spinWheel(this.spinDirectionValue, null);
        },
        rotate(direction) {
          this.wheelSpinning = true;
          let rotationAmount = 360 / this.getSegments.length;
          this.$refs.digiwheel.spinWheel(direction, rotationAmount);
        },
        playIdleAnimation() {
          this.$refs.digiwheel.playIdleAnimation();
        },
        tierChipClass(index) {
          return index == this.selectedTier ? 'primary' : ''
        },
        loadWheel() {
          this.render = !this.render;
        },
        handleWheelStopped() {
          setTimeout(() => {
            this.wheelSpinning = false;
          }, 3000);
          
        },
        async downloadGame() {
            window.location.href = this.presignedUrl;
        },
      }
    }
    </script>
    
    <style scoped>
    .custom_wheel_container {
        width: 30vw;
        height: 30vw;
        margin: auto;
        margin-bottom: 30px !important;
        overflow: hidden;
    }
    
    .header,
    .filters {
      max-width: 80%;
      margin: 0 auto;
    }
    
    .picker {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    
      max-width: 90%;
      height: 40vw;
      margin: 16px auto 0;
      background-color: #f8f8fa;
      border-radius: 4px;
    
      text-align: center;
      font-size: 18px;
      color: #bbbcc9;
    }
    
    .picker:focus {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    }
    
    .picker_arrow {
      font-size: 18px;
      height: 5px;
      width: 24px;
      padding: 4px;
      margin: 0 auto;
    }
    
    .picker_arrow.bottom {
      transform: rotate(180deg);
    }
    
    .picker_chosen {
      background-color: #f8f8fa;
      border-top: 1px solid #dddde4;
      border-bottom: 1px solid #dddde4;
      color: #121212;
      font-size: 20px;
    }
    
    .filters {
      display: flex;
      flex-wrap: wrap;
    }
    
    .filters label {
      margin-top: 12px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    
    .filters label span {
      margin-left: 8px;
    }
    
    @media (max-width: 768px) {
      .filters label {
        width: 100%;
      }
    }
    .btn-toggle {
      flex-direction: column;
    }
    
    .nonActiveChip {
      border: 1px solid black;
      border-radius: 1em;
    }
    #loadingOverlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loader {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    </style>