<template>
  <v-container fluid fill-height class="background fill-height">
    <v-card class="white mx-auto" width="60vw" height="50vh" min-height="480px">
      
      <div class="white">
        <v-img max-width="20vw" class="mx-auto" src="../assets/DigiWheel_Logo_Evolution_Company_RGB_Yellow_Black.svg"/>
      </div>

      <p class="text-h10 pt-2 pb-2 white text-center">{{ $t('login.title') }}</p>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="text-center"
      >
        <v-text-field
          v-model="username"
          :placeholder="$t('login.username')"
          class="mb-n2 rounded-lg mx-8"
          outlined
          density="compact"
          single-line
          data-test="email-input"
        ></v-text-field>
    
        <v-text-field
          v-model="password"
          :placeholder="$t('login.password')"
          class="mb-n2 rounded-lg mx-8"
          outlined
          single-line
          density="compact"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          data-test="password-input"
        ></v-text-field>

        <v-btn
          width="35vw"
          color="black white--text"
          class="rounded-lg mb-3"
          @click="loginClick()"
          data-test="btn-login"
        >
          {{ $t('login.enter') }}
        </v-btn>
        <p style="display: none">{{ $t('login.reset') }}</p>
      </v-form>

    </v-card>
  </v-container>
</template>

  <script>
import { mapActions, mapGetters } from 'vuex';
  export default {
    name: "LogIn",
    data: () => ({
      valid: false,
      username: '',
      password: '',
      showPassword: false
    }),
    mounted() {
      this.setLoginPage(true);
    },
    computed: {
      ...mapGetters([
        "getLoginFailed",
      ]),
      loginFailed() {
        return this.getLoginFailed;
      },
    },
    methods: {
      ...mapActions(["login", "setLoginPage"]),
      loginClick() {
        this.login({'username': this.username.trim(), 'password': this.password.trim() })
          .then(() => {
            if (this.getLoginFailed) {
              this.$toast.error("Incorrect login. Please try again.");
            }  
          })
         
      }
    },
  };
  </script>
  
  <style scoped>
  .fadeout {
    animation: fadeout 2s forwards;
  }
  
  @keyframes fadeout {
    to {
      opacity: 0;
      visibility: hidden;
    }
  }

  </style>
  