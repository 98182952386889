
export async function prepareNextWheel(gameJson, previousWinningSegment, isLevelUp){
    applyAssets(gameJson, previousWinningSegment);
    gameJson = await applyGameRule(gameJson, previousWinningSegment, isLevelUp);  
    return gameJson;
}

async function applyGameRule(gameJson, previousWinningSegment, isLevelUp){
    if(!gameJson.gameRule){
        return gameJson;
    }
    switch(gameJson.gameRule){
        case "RemoveWinningSegment":
            if(previousWinningSegment){
                if (gameJson.rules && gameJson.rules.type) {
                    if(gameJson.segments.find((seg) => previousWinningSegment.id != seg.id) == null) return gameJson;
                    if (gameJson.rules.type == "ReplaceNext") { 
                        let originalIndex = gameJson.segments.findIndex(segment => segment.id == previousWinningSegment.id);
                        let clonedIndex = originalIndex == gameJson.segments.length-1 ? 0 : originalIndex+1;
                        let segmentToClone = gameJson.segments[clonedIndex];
                        while(segmentToClone.id == previousWinningSegment.id){
                            clonedIndex = clonedIndex == gameJson.segments.length-1 ? 0 : clonedIndex+1;
                            segmentToClone = gameJson.segments[clonedIndex];
                        }
                        let clonedSegment = deepCopyFunction(segmentToClone);
                        for (let i = 0; i < gameJson.segments.length; i++) {
                            if (gameJson.segments[i]['id'] === previousWinningSegment.id) {
                                gameJson.segments[i] = clonedSegment;
                            }
                        }
                    } else if (gameJson.rules.type == "ReplacePrevious") { 
                        let originalIndex = gameJson.segments.findIndex(segment => segment.id == previousWinningSegment.id);
                        let clonedIndex = originalIndex == 0 ? gameJson.segments.length-1 : originalIndex-1;
                        let segmentToClone = gameJson.segments[clonedIndex];
                        while(segmentToClone.id == previousWinningSegment.id){
                            clonedIndex = clonedIndex == 0 ? gameJson.segments.length-1 : clonedIndex-1;
                            segmentToClone = gameJson.segments[clonedIndex];
                           }
                           let clonedSegment = deepCopyFunction(segmentToClone);
                           for (let i = 0; i < gameJson.segments.length; i++) {
                            if (gameJson.segments[i]['id'] === previousWinningSegment.id) {
                                gameJson.segments[i] = clonedSegment;
                            }
                        }
                    } else if (gameJson.rules.type == "ReplacementSegment") { //Replace segment with a specified segment
                        let originalIndex = gameJson.segments.findIndex(segment => segment.id == previousWinningSegment.id);
                        replaceSegmentAssets(gameJson, originalIndex, gameJson.rules.replacementSegment, isLevelUp);
                    } else if (gameJson.rules.type == "ReplacementGroup") { //Replace segment with a random segment from a specified group
                        let group = deepCopyFunction(gameJson.rules.replacementGroup);
                        if (group != null && group.length > 0) {
                            let replacementSegment = group[Math.floor(Math.random() * (group.length))];
                            let segmentIndex = gameJson.segments.findIndex(segment => segment.id == previousWinningSegment.id);
                            replaceSegmentAssets(gameJson, segmentIndex, replacementSegment, isLevelUp);
                        }
                    } else if (gameJson.rules.type == "ReplaceRandomSegment") { //Replace segment with a random segment from the wheel segments
                        let segmentsGroup = deepCopyFunction(gameJson.segments);
                        segmentsGroup = segmentsGroup.filter(segment => segment.id != previousWinningSegment.id);
                        let replacementSegment = segmentsGroup[Math.floor(Math.random() * (segmentsGroup.length))];
                        let segmentIndex = gameJson.segments.findIndex(segment => segment.id == previousWinningSegment.id);
                        replaceSegmentAssets(gameJson, segmentIndex, replacementSegment, isLevelUp);
                    } else {
                        gameJson.segments = gameJson.segments.filter(segment => segment.id != previousWinningSegment.id);
                    }
                } else {
                    gameJson.segments = gameJson.segments.filter(segment => segment.id != previousWinningSegment.id);
                }
            }
            return gameJson;
        case "Shuffle":
            if(previousWinningSegment){
                if (gameJson.rules && gameJson.rules.type) {
                        let shuffleSegments = [];
                        let freezeSegments = [];
                        let freezeIndexes = [];
                    if (gameJson.rules.type == "FreezeGroup") { // Shuffle every segment that doesn't match any of the groups specified
                        shuffleSegments = deepCopyFunction(gameJson.segments);
                        
                        shuffleSegments = shuffleSegments.filter((segment, index) => {
                            if (gameJson.rules.list.includes(segment.group)) {
                                freezeSegments.push(segment);
                                freezeIndexes.push(index);
                                return false;
                            }
                            return true;
                        });

                        shuffleArray(shuffleSegments);
                        insertFreezeSegments(shuffleSegments, freezeSegments, freezeIndexes);

                        gameJson.segments = shuffleSegments;
                    } else if (gameJson.rules.type == "FreezeWinningGroup") { // Shuffle every segment that doesn't match the winning segments group
                        shuffleSegments = deepCopyFunction(gameJson.segments);
                        
                        shuffleSegments = shuffleSegments.filter((segment, index) => {
                            if (previousWinningSegment.group == segment.group) {
                                freezeSegments.push(segment);
                                freezeIndexes.push(index);
                                return false;
                            }
                            return true;
                        });

                        shuffleArray(shuffleSegments);
                        insertFreezeSegments(shuffleSegments, freezeSegments, freezeIndexes);

                        gameJson.segments = shuffleSegments;
                    } else if (gameJson.rules.type == "FreezeIds") {  // Shuffle every segment that does not match any of the IDs specified
                        shuffleSegments = deepCopyFunction(gameJson.segments);
                        
                        shuffleSegments = shuffleSegments.filter((segment, index) => {
                            let segmentId = segment.id.toString();
                            if (gameJson.rules.list.includes(segmentId)) {
                                freezeSegments.push(segment);
                                freezeIndexes.push(index);
                                return false;
                            }
                            return true;
                        });

                        shuffleArray(shuffleSegments);
                        insertFreezeSegments(shuffleSegments, freezeSegments, freezeIndexes);

                        gameJson.segments = shuffleSegments;
                    } else if (gameJson.rules.type == "FreezeValues") {  // Shuffle every segment that does not match any of the segment values specified
                        shuffleSegments = deepCopyFunction(gameJson.segments);
                        
                        shuffleSegments = shuffleSegments.filter((segment, index) => {
                            if (gameJson.rules.list.includes(segment.value)) {
                                freezeSegments.push(segment);
                                freezeIndexes.push(index);
                                return false;
                            }
                            return true;
                        });

                        shuffleArray(shuffleSegments);
                        insertFreezeSegments(shuffleSegments, freezeSegments, freezeIndexes);

                        gameJson.segments = shuffleSegments;
                    } else if (gameJson.rules.type == "FreezeWinningSegment") { // Shuffle every segment except the winning segment
                        let shuffleSegments = deepCopyFunction(gameJson.segments);
                        let freezeSegment;
                        let freezeIndex;
                        
                        shuffleSegments = shuffleSegments.filter((segment, index) => {
                            if (previousWinningSegment.id == segment.id) {
                                freezeSegment = segment;
                                freezeIndex = index;
                                return false;
                            }
                            return true;
                        });

                        shuffleArray(shuffleSegments);
                        shuffleSegments.splice(freezeIndex, 0, freezeSegment);
                        gameJson.segments = shuffleSegments;
                    } else if (gameJson.rules.type == "ShuffleGroups" && gameJson.rules.list) { // Shuffle any segment that matches any of the groups specified
                        freezeSegments = deepCopyFunction(gameJson.segments);
                        
                        freezeSegments = freezeSegments.filter((segment, index) => {
                            if (gameJson.rules.list.includes(segment.group)) {
                                shuffleSegments.push(segment);
                                freezeIndexes.push(index)
                                return false;
                            }
                            return true;
                        });

                        insertShuffleSegments(shuffleSegments, freezeSegments, freezeIndexes);
                        gameJson.segments = freezeSegments;
                    } else if (gameJson.rules.type == "ShuffleWinningGroup") { // Shuffle any segment that matches the winning group
                        freezeSegments = deepCopyFunction(gameJson.segments);
                        
                        freezeSegments = freezeSegments.filter((segment, index) => {
                            if (previousWinningSegment.group == segment.group) {
                                shuffleSegments.push(segment);
                                freezeIndexes.push(index);
                                return false;
                            }
                            return true;
                        });

                        insertShuffleSegments(shuffleSegments, freezeSegments, freezeIndexes);
                        gameJson.segments = freezeSegments;
                    } else if (gameJson.rules.type == "ShuffleIds") {  // Shuffle any segment that matches any of the ids specified
                        freezeSegments = deepCopyFunction(gameJson.segments);
                        
                        freezeSegments = freezeSegments.filter((segment, index) => {
                            let segmentId = segment.id.toString();
                            if (gameJson.rules.list.includes(segmentId)) {
                                shuffleSegments.push(segment);
                                freezeIndexes.push(index)
                                return false;
                            }
                            return true;
                        });

                        insertShuffleSegments(shuffleSegments, freezeSegments, freezeIndexes);
                        gameJson.segments = freezeSegments;
                    } else if (gameJson.rules.type == "ShuffleSegments") { // Shuffle any segment that matches any of the values specified
                        freezeSegments = deepCopyFunction(gameJson.segments);
                        
                        freezeSegments = freezeSegments.filter((segment, index) => {
                            if (gameJson.rules.list.includes(segment.value)) {
                                shuffleSegments.push(segment);
                                freezeIndexes.push(index)
                                return false;
                            }
                            return true;
                        });

                        insertShuffleSegments(shuffleSegments, freezeSegments, freezeIndexes);
                        gameJson.segments = freezeSegments;
                    }
                } else { // Shuffle entire wheel
                    gameJson.segments = shuffleArray(gameJson.segments);
                }
            }
            return gameJson;
        case "RemoveWinningGroup":
            if(previousWinningSegment){
                if (gameJson.rules && gameJson.rules.type) {
                    const groupName = previousWinningSegment.group;
                    if (gameJson.rules.type == "ReplacementSegment" && gameJson.rules.replacementSegment) { // Replace all segments in the winning group with the specified segment
                        for (let i = 0; i < gameJson.segments.length; i++) {
                            if (gameJson.segments[i].group == groupName) {
                                replaceSegmentAssets(gameJson, i, gameJson.rules.replacementSegment, isLevelUp);
                            }
                        }
                    } else if (gameJson.rules.type == "ReplacementGroupSequential") { // Replace all segments in the winning group with another group of segments in sequential order
                        if (groupName in gameJson.rules.groupsMapping) {
                            let group = gameJson.rules.groups[gameJson.rules.groupsMapping[groupName]];
                            gameJson.segments.forEach(segment => {
                                if (segment.group == gameJson.rules.groupsMapping[groupName]) {
                                    group.push(segment);
                                }
                            });

                            let currentGroupIndex = 0;
                            for (let i = 0; i < gameJson.segments.length; i++) {
                                if (gameJson.segments[i].group == groupName) {
                                    replaceSegmentAssets(gameJson, i, group[currentGroupIndex], isLevelUp);
                                    currentGroupIndex = currentGroupIndex == group.length - 1 ? 0 : currentGroupIndex + 1;
                                } 
                            }
                        }
                    } else if (gameJson.rules.type == "ReplacementGroupRandom") { // Replace all segments in the winning group with another group of segments in random order
                        if (groupName in gameJson.rules.groupsMapping) {
                            let group = [];
                            if (gameJson.rules.groupsMapping[groupName] == null) { //If a mapping == null, create replacement group from wheel and other groups
                                gameJson.segments.forEach(segment => {
                                    if (segment.group != groupName) {
                                        group.push(segment);
                                    }
                                });
                                const keys = Object.keys(gameJson.rules.groups);
                                for (let i = 0; i < keys.length; i++) {
                                    if (groupName != keys[i]) {
                                        for (let j = 0; j < gameJson.rules.groups[keys[i]].length; j++) {
                                            group.push(gameJson.rules.groups[keys[i]][j]);
                                        }
                                    }
                                }
                            } else {
                                group = gameJson.rules.groups[gameJson.rules.groupsMapping[groupName]];
                                gameJson.segments.forEach(segment => {
                                    if (segment.group == gameJson.rules.groupsMapping[groupName]) {
                                        group.push(segment);
                                    }
                                });
                            }
                            
                            for (let i = 0; i < gameJson.segments.length; i++) {
                                if (gameJson.segments[i].group == groupName) {
                                    let randomSegment = group[Math.floor(Math.random() * (group.length))];
                                    replaceSegmentAssets(gameJson, i, randomSegment, isLevelUp);
                                } 
                            }
                        }
                    } else if (gameJson.rules.type == "Replace") { // Replace all segments in the winning group with randomly selected segments from the wheel except the winning group segments
                        let segmentsCopy = deepCopyFunction(gameJson.segments);
                        segmentsCopy = segmentsCopy.filter(segment => segment.group != groupName);
                        if (segmentsCopy != null && segmentsCopy.length > 0) {
                            for (let i = 0; i < gameJson.segments.length; i++) {
                                if (gameJson.segments[i].group == groupName) {
                                    let randomSegment = segmentsCopy[Math.floor(Math.random() * (segmentsCopy.length))];
                                    replaceSegmentAssets(gameJson, i, randomSegment, isLevelUp);
                                } 
                            }
                        }
                    }
                } else {
                    gameJson.segments = gameJson.segments.filter(segment => segment.group != previousWinningSegment.group)
                }
            }
            return gameJson;
        case "Custom":
            if(gameJson.rules && gameJson.rules.ruleFile){
                let ruleFile = getCustomGameAsset(gameJson.rules.ruleFile);
                await fetch(ruleFile)
                        .then(response => response.text())
                        .then(txt => Function('gameJson', 'previousWinningSegment', txt))
                        .then(newFunction => {
                            gameJson = newFunction(gameJson, previousWinningSegment);
                        })
            }
            return gameJson;
        default: 
            return gameJson;
    }
}

function applyAssets(gameJson, previousWinningSegment) {
    if(previousWinningSegment && previousWinningSegment.background){
        gameJson.background = previousWinningSegment.background;
    }
    if(previousWinningSegment && previousWinningSegment.backgroundOverlay){
        gameJson.backgroundOverlay = previousWinningSegment.backgroundOverlay;
    }
    if(previousWinningSegment && previousWinningSegment.wheelOverlay){
        gameJson.wheelOverlay = previousWinningSegment.wheelOverlay;
    }
    if(previousWinningSegment && previousWinningSegment.foreground){
        gameJson.foreground = previousWinningSegment.foreground;
    }
    if(previousWinningSegment && previousWinningSegment.idle){
        gameJson.idle = previousWinningSegment.idle;
    }
}

function replaceSegmentAssets(gameJson, segmentIndex, replacementSegment) {
    let originalSegmentId = gameJson.segments[segmentIndex].id;
    gameJson.segments[segmentIndex] = deepCopyFunction(replacementSegment);
    gameJson.segments[segmentIndex].id = originalSegmentId; //Keep segment IDs consistent throughout segment swapping 
    gameJson.segments[segmentIndex].image = replacementSegment.image ? replacementSegment.image : getCustomGameAsset(replacementSegment.image);
    gameJson.segments[segmentIndex].bannerImage = replacementSegment.bannerImage ? replacementSegment.bannerImage : getCustomGameAsset(replacementSegment.bannerImage);
    gameJson.segments[segmentIndex].backgroundImage = replacementSegment.backgroundImage ? replacementSegment.backgroundImage : getCustomGameAsset(replacementSegment.backgroundImage);
    gameJson.segments[segmentIndex].planeImage = replacementSegment.planeImage ? replacementSegment.planeImage : getCustomGameAsset(replacementSegment.planeImage);
}

function insertFreezeSegments(shuffleSegments, freezeSegments, freezeIndexes) {
    let highestIndex = Math.max(...freezeIndexes);
    while (shuffleSegments.length <= highestIndex) {
        shuffleSegments.push(null);
    }
    
    for (let i = 0; i < freezeSegments.length; i++) { 
        shuffleSegments.splice(freezeIndexes[i], 0, freezeSegments[i]);
    }
    while (shuffleSegments[shuffleSegments.length-1] == null) {
        shuffleSegments.pop();
    }
}

function insertShuffleSegments(shuffleSegments, freezeSegments, freezeIndexes) {
    let highestIndex = Math.max(...freezeIndexes);
    while (freezeSegments.length <= highestIndex) {
        freezeSegments.push(null);
    }
    shuffleArray(shuffleSegments);
    for (let i = 0; i < shuffleSegments.length; i++) { 
        freezeSegments.splice(freezeIndexes[i], 0, shuffleSegments[i]);
    }
    while (freezeSegments[freezeSegments.length-1] == null) {
        freezeSegments.pop();
    }
}

export function deepCopyFunction(inObject)  {
    let outObject, value, key
    
    if (typeof inObject !== "object" || inObject === null) {
        return inObject;
    }
    
    outObject = Array.isArray(inObject) ? [] : {};
    for (key in inObject) {
        value = inObject[key];
        outObject[key] = deepCopyFunction(value);
    }
    return outObject
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

//TODO do a subsititue
function getCustomGameAsset (assetName) {
	return null;
}