<template>
  
  <v-card class="elevation-0">
    <div id="loadingOverlay" :style="loadingOverlayStyle">
        <div class="loader" :style="spinnerStyle"></div>
    </div>

    <v-card-title class="font-weight-normal">{{ $t('rules.title') }}</v-card-title>
    <v-card-subtitle class="font-weight-light">{{ $t('rules.subtitle') }}</v-card-subtitle>  
    
    <v-row class="">
      <v-col cols="3" class="pl-6 pt-8 pr-1">
        <label class="ml-6">{{ $t('rules.selectGame') }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1 pt-6">
        <v-select
          v-model="selectedCustomGame" 
          @change="selectCustomGame()"  
          :items="customGames"
          class="selectWidth ml-6"
          label="Games"
          outlined
          dense
          clearable
          flat
          solo
          searchable
        >
          <template v-slot:prepend-item>
            <v-text-field v-model="searchGamesTerm" placeholder="Search" @input="searchGames" class="ml-2 mr-2 pt-0"></v-text-field>
            <v-divider class=""></v-divider>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedCustomGame != null">
      <v-col cols="3" class="pl-6 pt-5 pr-1">
        <label class="ml-6">{{ $t('rules.selectRule') }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1">
        <v-select
          v-model="selectedRule"   
          :items="rules"
          class="selectWidth ml-6"
          label="Rules"
          outlined
          dense
          clearable
          :hint="selectedRuleHint"
          persistent-hint
          @change="selectedRuleChanged"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedRule != null && selectedRule != 'Custom' && selectedRule != 'Shuffle'">
      <v-col cols="3" class="pl-6 pt-5 pr-1">
        <label class="ml-6">{{ $t('rules.selectSubRule') }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1">
        <v-select
          v-model="selectedSubRule"   
          :items="subRuleItemsMapped"
          class="selectWidth ml-6"
          label="Sub Rules"
          outlined
          dense
          :hint="selectedSubRuleHint"
          persistent-hint
          @change="selectedSubRuleChanged"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row v-if="selectedRule == 'Custom'">
      <v-col cols="8" class="ml-8 pb-0">
        <v-textarea
          v-model="customRuleDraft"   
          outlined
          label="Custom Rule" 
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row v-if="selectedRule == 'Custom'" class="mt-0">
      <v-col v-if="selectedRule == 'Custom'" cols="2" class="ml-10 pt-0 mr-n10">
        <v-btn outlined rounded color="black" class=" black--text" @click="openRuleTipsDialog = true">{{ $t('rules.ruleTips') }}</v-btn>
      </v-col>
      <v-col cols="1" class="pt-0 col col-2 pl-0">
        <v-btn rounded color="primary" class=" black--text" @click="saveCustomRule()">{{ $t('rules.save') }}</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="selectedRule == 'Remove Winning Group' && selectedSubRule.includes('New Group')">
      <v-col cols="3" class="pl-6 pt-5 pr-1">
        <label class="ml-6">{{ $t('rules.groupName') }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1">
        <v-text-field
          v-model="replacementGroupName"   
          class="selectWidth ml-6"
          label="Group name"
          outlined
          dense
          clearable
          persistent-hint
          @change="setReplacementGroup"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row 
        v-for="group in replacementGroups"
        :key="group.name"
    >
      <v-col cols="3" class="pl-6 pt-5 pr-1">
        <label class="ml-6">{{ group.name }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1">
        <v-select
          v-model="group.mapping"   
          :items="filteredGroupNames(group.name)"
          class="selectWidth ml-6"
          label="Group mapping"
          outlined
          dense
          clearable
          persistent-hint
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row v-if="selectedRule != null && selectedRule == 'Shuffle'">
      <v-col cols="3" class="pl-6 pt-5 pr-1">
        <label class="ml-6">{{ $t('rules.type') }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1">
        <v-select
          v-model="shuffleType"   
          :items="shuffleTypes"
          class="selectWidth ml-6"
          label="Type"
          outlined
          dense
          @change="resetShuffleValueList"
          clearable
          :hint="selectedShuffleTypeHint"
          persistent-hint
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedRule != null && selectedRule == 'Shuffle' && shuffleType != null">
      <v-col cols="3" class="pl-6 pt-5 pr-1">
        <label class="ml-6">{{ $t('rules.impactedSegments') }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1">
        <v-select
          v-model="shuffleImpactedSegment"   
          :items="impactedSegmentsMapped"
          class="selectWidth ml-6"
          label="Impacted segments"
          outlined
          @change="resetShuffleValueList"
          dense
          clearable
          persistent-hint
          :hint="shuffleType == 'Include' ? selectedShuffleImpactedSegmentsIncludeListHint : selectedShuffleImpactedSegmentsExcludeListHint"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-divider class="mt-3 mb-5"></v-divider>
    
    <v-row v-if="showShuffleValueList">
      <v-col cols="3" class="pl-6 pt-5 pr-1">
        <label class="ml-6">{{ shuffleImpactedSegment }}:</label>
      </v-col>
      <v-col cols="5" class="pl-1">
        <v-combobox
          v-model="shuffleValueList"
          :items="shuffleValueListMapped"
          class="selectWidth ml-6"
          outlined
          dense
          clearable
          persistent-hint
          multiple
          chips
        >
        </v-combobox>
      </v-col>
    </v-row>
    <div v-if="showSegmentDefinitionUI" class="pl-9">
      <v-card-title v-if="selectedSubRule == 'New Segment'" class="ml-n8 font-weight-normal">{{ $t('rules.newSegmentHeading') }}</v-card-title>
      <v-card-title v-if="selectedSubRule.includes('New Group')" class="ml-n8 font-weight-normal">{{ $t('rules.newGroupHeading') }}</v-card-title>
      <v-row>
        <v-col cols="5" :lg="4" 
          @dragover="handleSegmentContentDragOver"
          @drop="handleSegmentContentDrop">
          <p class="mb-1 text-subtitle-2">{{ $t("details.upload_segment_content")}}
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t('details.upload_segment_subtitle') }}</span>
            </v-tooltip>
          </p>

          <v-file-input
            v-model="segmentFiles"
            label="Drag and drop, or browse your files"
            accept="image/*"
            counter 
            multiple
            outlined
            :show-size="true"
            prepend-icon=""
            class="file_upload"
            @change="onSegmentContentUploaded"
          >
            <template v-slot:selection="{ text }">
              <v-chip dark label small class="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="5" :lg="4"
          @dragover="handleSegmentAnimationDragOver"
          @drop="handleSegmentAnimationDrop">
          <p class="mb-1 text-subtitle-2">{{ $t("details.upload_segment_animations")}}
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t('details.upload_segment_animations_subtitle') }}</span>
            </v-tooltip>
          </p>

          <v-file-input
            v-model="segmentAnimationFiles"
            label="Drag and drop, or browse your files"
            accept=".mp4, .webm"
            counter
            multiple
            outlined
            :show-size="true"
            prepend-icon=""
            class="file_upload"
            @change="onSegmentAnimationsUploaded"
          >
            <template v-slot:selection="{ text }">
              <v-chip dark label small class="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="4">
          <v-progress-linear
            v-if="segmentUploading"
            :value="segmentUploadProgress"
            color="primary"
            height="5"
            class="ma-2"
          ></v-progress-linear>
          <p
            v-if="segmentUploading"
            class="ml-3 text-subtitle-2 font-weight-light"
          >
            Uploading {{ segmentUploadedCount }} of {{ segmentFiles.length }}
          </p>
          <v-progress-linear
            v-if="segmentAnimationUploading"
            :value="segmentAnimationUploadProgress"
            color="primary"
            height="5"
            class="ma-2"
          ></v-progress-linear>
          <p
            v-if="segmentAnimationUploading"
            class="ml-3 text-subtitle-2 font-weight-light"
          >
            Uploading {{ segmentAnimationUploadedCount }} of {{ segmentAnimationFiles.length }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" :lg="3">
          <p class="mb-0 text-subtitle-2">{{ $t('details.segment_animation_size') }}
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t('details.segment_animation_size_subtitle') }}</span>
            </v-tooltip>
          </p>
          <v-text-field
            v-model="segmentWinningAnimationSize"
            label="Enter a value"
            :rules="[numberBetweenOneAndHundred]"
            item-text="name"
            style="max-height: 30%;"
            return-object
            dense
            solo
            flat
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" :lg="3">
          <p class="mb-0 text-subtitle-2">{{ $t('details.segment_animation_duration') }}
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t('details.segment_animation_duration_subtitle') }}</span>
            </v-tooltip>
          </p>
          <v-text-field
            v-model="segmentWinningAnimationDuration"
            label="Enter a value"
            :rules="[numberGreaterThanZero]"
            item-text="name"
            return-object
            dense
            solo
            flat
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" :lg="3">
          <p class="mb-0 text-subtitle-2">{{ $t('details.segment_animation_delay') }}
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t('details.segment_animation_delay_subtitle') }}</span>
            </v-tooltip>
          </p>
          <v-text-field
            v-model="segmentWinningAnimationDelay"
            label="Enter a value"
            :rules="[numberGreaterThanZero]"
            
            item-text="name"
            return-object
            dense
            solo
            flat
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="selectedSubRule.includes('New Group')">
        <v-divider class="mb-3"></v-divider>
      </v-row>

      <v-row v-if="selectedSubRule.includes('New Group')">
        <v-col cols="12" class="mr-3">
          <v-chip-group
            v-model="selectedSegmentDefinition"
          >
            <v-chip
              v-for="(seg, index) in replacementGroup"
              :key="`segment_definition_${index}`"
              rounded
              small
              mandatory
              active-class="primary"
              @change="selectSegmentDefinition(index)"
              :value="index"
            >
              {{ seg.name ? seg.name : index + 1 }}
            </v-chip>
            <v-btn
              elevation="0"
              small
              rounded
              class="mr-2 red white--text float-right"
              @click="deleteSegmentDialog = true"
              data-test="add_seg-btn"
              ><v-icon>mdi-minus</v-icon></v-btn
            >
            <v-btn
              elevation="0"
              small
              rounded
              class="black white--text float-right"
              @click="addSegment"
              data-test="add_seg-btn"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </v-chip-group>
        </v-col>
      </v-row>

      <v-row v-if="selectedSubRule.includes('New Group')">
        <v-divider class="mb-3"></v-divider>
      </v-row>

      <v-row>
        <v-col cols="4" :lg="2">
          <p class="mb-2 text-subtitle-2"><span>Segment Value</span>
            <v-tooltip top content-class="segment_value_tooltip_width">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t('details.segment_value_subtitle') }}</span>
            </v-tooltip>
          </p>
          <v-text-field
            v-model="segmentName"
            placeholder="Segment name or value"
            :rules="segmentNameRules"
            dense
            solo
            flat
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" :lg="2">
          <p class="mb-2 text-subtitle-2">
            <span>Groups</span>
            <v-tooltip top content-class="segment_value_tooltip_width">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t('details.segment_groups_subtitle') }}</span>
            </v-tooltip>
          </p>
          <v-text-field
            v-model="segmentGroup"
            placeholder="Groups"
            :rules="segmentGroupRules"
            dense
            solo
            flat
            outlined
            :key="selectedSegmentDefinition"
            :disabled="replacementGroupName != null"
          ></v-text-field>
        </v-col>

        <v-col cols="4" :lg="3">
          <p class="mb-2 text-subtitle-2">
            Segment Image
          </p>
          <v-select
            v-model="segmentImage"
            :items="segmentImageOptions"
            label="Make a selection"
            :item-text="assetNameFormatted"
            return-object
            dense
            solo
            flat
            outlined
            @change="selectSegmentImage"
            clearable
            searchable
          >
            <template v-slot:prepend-item>
              <v-text-field v-model="searchSegmentImageTerm" placeholder="Search" @input="searchSegmentImage" class="ml-2 mr-2 pt-0"></v-text-field>
              <v-divider class=""></v-divider>
            </template>
            <template v-slot:item="{ item }">
              {{ assetNameFormatted(item) }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="4" :lg="3">
          <p class="mb-2 text-subtitle-2">
            Winning Animation
          </p>
          <v-select
            v-model="segmentAnimation"
            :items="segmentAnimationOptions"
            label="Make a selection"
            :item-text="assetNameFormatted"
            return-object
            dense
            flat
            solo
            outlined
            @change="selectSegmentAnimation"
            clearable
            searchable
          >
            <template v-slot:prepend-item>
              <v-text-field v-model="searchSegmentAnimationTerm" placeholder="Search" @input="searchSegmentAnimation" class="ml-2 mr-2 pt-0"></v-text-field>
              <v-divider class=""></v-divider>
            </template>
            <template v-slot:item="{ item }">
              {{ assetNameFormatted(item) }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="4" :lg="2">
          <p class="mb-2 text-subtitle-2">
            Bonus Segment
          </p>
          <v-btn id="yesBonusButton" :style="disableBonusSwitch" dense @click="setSegmentBonus(true)" :class="getSegmentBonus ? 'yes_button_bonus' : 'yes_button_nobonus'" class="px-6 text-none rounded-lg">
              Yes
          </v-btn>
          <v-btn id="noBonusButton" :style="disableBonusSwitch" dense @click="setSegmentBonus(false)" :class="getSegmentBonus ? 'no_button_bonus' : 'no_button_nobonus'" class="px-6 text-none rounded-lg">
              No
          </v-btn>
        </v-col>
        
      </v-row>
    </div>

    <v-row v-if="selectedSubRule != null" class="d-flex mt-4 fixed-row mb-4">
        <v-btn outlined class="ml-auto mr-4 red white--text text-capitalize" data-test="cancel-btn" >{{ $t('action.cancel') }}</v-btn>
        <v-btn outlined class="mr-4 black--text text-capitalize" @click="applyRule" data-test="continue-btn">{{ $t('rules.apply') }}</v-btn>
    </v-row>

    <v-dialog v-model="deleteSegmentDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Delete Segment?</v-card-title>
        <v-card-text>  This will remove all instances of the segment in the build wheel step. </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="deleteSelectedSegmentDefinition">Yes</v-btn>
          <v-btn color="red" text @click="deleteSegmentDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openRuleTipsDialog" max-width="820px">
      <v-card>
          <v-card-title class="headline align-self-center text-center">{{ $t('rules.ruleTips') }}</v-card-title>
          <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('rules.thingsToRemember') }}: </b></v-card-text>
          <v-card-text class="black--text pt-0 pb-2 pl-10">- {{ $t('rules.validGameJson') }}</v-card-text>
          <!-- <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('details.numberOfTiers') }}:</b> {{ getSelectedGame.tiers ? getSelectedGame.tiers.length + 1 : 1 }}</v-card-text>
          <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('details.tierOneSegmentCount') }}:</b> {{ getSelectedGame.no_of_segments }}</v-card-text>
          <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('details.bonusSegmentCount') }}:</b> {{ getNumberOfBonusSegments(getSelectedGame.segments) }} </v-card-text> -->
          <v-card-text class="black--text pt-3 pb-0 pl-10"> <b>{{ $t('rules.sampleCode') }}: </b></v-card-text>
          <img src="@/assets/rule_tips.png" width="760px" class="ml-6 pa-6 pt-2">
          
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined rounded color="primary" class=" black--text"  @click="openRuleTipsDialog = false">Close</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
  </v-card>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EMPTY_SEGMENT_OBJECT } from '@/utils/helper';

export default {
  name: 'GameRules',
  data () {
    return {
      customGames: [],
      customGamesCopy: [],
      selectedCustomGame: null,
      selectedRule: null,
      selectedSubRule: "Default",
      // rules: [
      //   "Remove Winning Segment",
      //   "Remove Winning Group",
      //   "Shuffle",
      //   "Custom"
      // ],
      rules: [
        "Remove Winning Segment",
        "Remove Winning Group",
        "Shuffle",
        "Custom"
      ],
      removeWinningSegmentSubRules: [
        {
          name: "Default",
          value: ""
        },
        {
          name: "Random",
          value: "ReplaceRandomSegment"
        },
        {
          name: "New Segment",
          value: "ReplacementSegment"
        },
        {
          name: "New Group",
          value: "ReplacementGroup"
        },
        {
          name: "Next",
          value: "ReplaceNext"
        },
        {
          name: "Previous",
          value: "ReplacePrevious"
        }
      ],
      removeWinningGroupSubRules: [
        {
          name: "Default",
          value: ""
        },
        {
          name: "New Segment",
          value: "ReplacementSegment"
        },
        {
          name: "New Group Random",
          value: "ReplacementGroupRandom"
        },
        {
          name: "New Group Sequential",
          value: "ReplacementGroupSequential"
        },
        {
          name: "Replace",
          value: "Replace"
        }
      ],
      searchGamesTerm: '',
      downloading: false,
      fileRules: [
        (value) =>
          !value || value.size < 10000000 || "File size should not exceed 10 MB!", // TODO - specify correct size limit
      ],
      segmentNameRules: [
        v => !!v || 'Name is required',
        v => /^[A-Za-z0-9\s\-@#!.'"\$€£¥]*$/.test(v) || "Segment name invalid",
      ],
      segmentGroupRules: [
        v => /^[A-Za-z0-9\s\-@#!.'"\$€£¥]+(,[A-Za-z0-9\s\-@#!.'"\$€£¥]+)*(?<!\s)$/.test(v) || "Value must be a comma-separated list of valid characters."
      ],
      segmentFiles: [],
      segmentAnimationFiles: [],
      segmentItems: [],
      segmentUploading: false,
      segmentUploadProgress: 0,
      segmentUploadedCount: 0,
      segmentAnimationUploading: false,
      segmentAnimationUploadProgress: 0,
      segmentAnimationUploadedCount: 0,
      segmentBonusCooldown: false,
      searchSegmentImageTerm: '',
      segmentImageOptions: [],
      segmentImageOptionsCopy: [],
      searchSegmentAnimationTerm: '',
      segmentAnimationOptions: [],
      segmentAnimationOptionsCopy: [],
      deleteSegmentDialog: false,
      selectedCustomGameObject: null,
      selectedSegmentDefinition: 0,
      replacementSegment: null,
      replacementGroup: [],
      segmentBonus: false,
      shuffleType: null,
      shuffleTypes: [
        "Include",
        "Exclude"
      ],
      shuffleImpactedSegment: null,
      shuffleImpactedSegmentsIncludeList: [
        {
          name: "Default",
          value: ""
        }, 
        {
          name: "Winning Group",
          value: "ShuffleWinningGroup"
        },
        {
          name: "Selected Groups",
          value: "ShuffleGroups"
        },
        {
          name: "Selected IDs",
          value: "ShuffleIds"
        },
        {
          name: "Selected Values",
          value: "ShuffleSegments"
        },
      ],
      shuffleImpactedSegmentsExcludeList: [
        {
          name: "Default",
          value: ""
        },
        {
          name: "Winning Segment",
          value: "FreezeWinningSegment"
        },
        {
          name: "Winning Group",
          value: "FreezeWinningGroup"
        },
        {
          name: "Selected Groups",
          value: "FreezeGroup"
        },
        {
          name: "Selected IDs",
          value: "FreezeIds"
        },
        {
          name: "Selected Values",
          value: "FreezeValues"
        },
      ],
      shuffleValueList: null,
      replacementGroupName: null,
      replacementGroups: [],
      watchTrigger: false,
      openRuleTipsDialog: false,
      customRuleDraft: '',
      customRule: null,
    }
  },
  async created() {
    if (this.getGamesList != null && this.getGamesList.length > 0) return;
    await this.getAllWheels({tenantId: this.getTenant, update: 'addNewGameAction'});
    this.loading = false;
    this.loadCustomGames();
  },
  async mounted() {
    this.downloading = true;
    if (this.customGames.length == 0) {
      this.loadCustomGames();
    }
    if (this.getSelectedGame.name) {
      this.selectedCustomGame = this.getSelectedGame.name;
      this.selectedCustomGameObject = this.getGamesList.find(game => game.displayName == this.selectedCustomGame);
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/${this.selectedCustomGameObject.noOfSegments}`, tenantId: this.getTenant, update:"updateSegmentOptions"});
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/winning-animation`, tenantId: this.getTenant, update:"updateWinningSegmentAnimations"})
      this.segmentImageOptions = [...this.getSegmentOptions].reverse();
      this.segmentImageOptionsCopy = this.segmentImageOptions;
      this.segmentAnimationOptions = [...this.getWinningAnimationsOptions].reverse();
      this.segmentAnimationOptionsCopy = this.segmentAnimationOptions;
    }
    this.addSegment();
    this.replacementSegment = Object.assign({}, EMPTY_SEGMENT_OBJECT);
    this.replacementSegment.value = "Segment 1";

    if (this.getSelectedGame.gameRule) {
      this.selectedRule = this.parseGameRule(this.getSelectedGame.gameRule);
      if (this.getSelectedGame.rules) {
        this.loadExistingRuleSettings();
      } else if (this.selectedRule == "Shuffle" && !this.getSelectedGame.rules) {
        this.shuffleType = "Include";
        this.shuffleImpactedSegment = "Default";
      }
    }
    
    this.downloading = false;
  },
  computed: {
    ...mapGetters([
      "getGamesList", 
      "getSegmentOptions",
      "getWinningAnimationsOptions",
      "getTenant",
      "getUploadContentError",
      "getSelectedGame",
      "getCustomGameRuleContentId"
    ]),
    
    disableBonusSwitch() {
      return this.getSelectedGame && this.getSelectedGame.tiers.length == 0 ? "pointer-events: none; opacity: 0.5 !important;" : "";
    },
    showShuffleValueList() {
      return this.selectedRule == 'Shuffle' && this.shuffleImpactedSegment != null && this.shuffleImpactedSegment != 'Default'
       && this.shuffleImpactedSegment != 'Winning Group' && this.shuffleImpactedSegment != 'Winning Segment';
    },
    showSegmentDefinitionUI() {
      return (this.selectedRule == 'Remove Winning Segment' || this.selectedRule == "Remove Winning Group") && 
        (this.selectedSubRule == 'New Segment' || this.selectedSubRule.includes('New Group')) ? true : false;
    },
    shuffleValueListMapped() {
      if (this.shuffleImpactedSegment == "Selected Groups") {
        const segmentGroups = this.getSelectedGame.segments
          .map(segment => segment.group)
          .filter(group => group !== undefined && group != null && group != '');
        return [...new Set(segmentGroups)];
      } else if (this.shuffleImpactedSegment == "Selected IDs") {
        return this.getSelectedGame.segments.map(segment => "Segment " + segment.id + " - " + segment.value);
      } else if (this.shuffleImpactedSegment == "Selected Values") {
        return this.getSelectedGame.segments.map(segment => segment.value);
      }
      return [];
    },
    segmentName: {
      get() {
        if (this.selectedSubRule == "New Segment") {
          return this.replacementSegment ? this.replacementSegment.value : null;
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.replacementGroup && this.replacementGroup[this.selectedSegmentDefinition] 
          ? this.replacementGroup[this.selectedSegmentDefinition].value : null;
        }
        return null;
      },
      set: _.debounce(function (name) {
        if (!name) return;
        if (this.selectedSubRule == "New Segment") {
          this.replacementSegment.value = name;
        } else if (this.selectedSubRule.includes("New Group")) {
          this.replacementGroup[this.selectedSegmentDefinition].value = name;
        }
      }, 500),
    },
    segmentGroup: {
      get() {
        this.watchTrigger;
        if (this.selectedSubRule == "New Segment") {
          return this.replacementSegment ? this.replacementSegment.group : null;
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.replacementGroup && this.replacementGroup[this.selectedSegmentDefinition] 
          ? this.replacementGroup[this.selectedSegmentDefinition].group : null;
        }
        return null;
      },
      set: _.debounce(function (group) {
        if (!group) return;
        group = group.split(',')
          .map(item => item.trim())
          .filter(Boolean)
          .join(', ');
        if (this.selectedSubRule == "New Segment") {
          this.replacementSegment.group = group;
        } else if (this.selectedSubRule.includes("New Group")) {
          this.replacementGroup[this.selectedSegmentDefinition].group = group;
        }
      }, 500),
    },
    segmentImage: {
      get() {
        if (this.selectedSubRule == "New Segment") {
          return this.replacementSegment ? this.replacementSegment.image : null;
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.replacementGroup && this.replacementGroup[this.selectedSegmentDefinition] 
          ? this.replacementGroup[this.selectedSegmentDefinition].image : null;
        }
        return null;
      },
      set: _.debounce(function (image) {
        if (!image) return;
        if (this.selectedSubRule == "New Segment") {
          this.replacementSegment.image = image;
        } else if (this.selectedSubRule.includes("New Group")) {
          this.replacementGroup[this.selectedSegmentDefinition].image = image;
        }
      }, 500),
    },
    segmentAnimation: {
      get() {
        if (this.selectedSubRule == "New Segment") {
          return this.replacementSegment ? this.replacementSegment.animation : null;
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.replacementGroup && this.replacementGroup[this.selectedSegmentDefinition] 
          ? this.replacementGroup[this.selectedSegmentDefinition].animation : null;
        }
        return null;
      },
      set: _.debounce(function (animation) {
        if (!animation) return;
        if (this.selectedSubRule == "New Segment") {
          this.replacementSegment.animation = animation;
        } else if (this.selectedSubRule.includes("New Group")) {
          this.replacementGroup[this.selectedSegmentDefinition].animation = animation;
        }
      }, 500),
    },
    segmentWinningAnimationDuration: {
      get() {
        if (this.selectedSubRule == "New Segment") {
          return this.replacementSegment ? this.replacementSegment.winningAnimationDuration : null;
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.replacementGroup && this.replacementGroup[this.selectedSegmentDefinition] 
          ? this.replacementGroup[this.selectedSegmentDefinition].winningAnimationDuration : null;
        }
        return null;
      },
      set: _.debounce(function (winningAnimationDuration) {
        if (!winningAnimationDuration) return;
        if (this.selectedSubRule == "New Segment") {
          this.replacementSegment.winningAnimationDuration = winningAnimationDuration;
        } else if (this.selectedSubRule.includes("New Group")) {
          this.replacementGroup[this.selectedSegmentDefinition].winningAnimationDuration = winningAnimationDuration;
        }
      }, 500),
    },
    segmentWinningAnimationDelay: {
      get() {
        if (this.selectedSubRule == "New Segment") {
          return this.replacementSegment ? this.replacementSegment.winningAnimationDelay : null;
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.replacementGroup && this.replacementGroup[this.selectedSegmentDefinition] 
          ? this.replacementGroup[this.selectedSegmentDefinition].winningAnimationDelay : null;
        }
        return null;
      },
      set: _.debounce(function (winningAnimationDelay) {
        if (!winningAnimationDelay) return;
        if (this.selectedSubRule == "New Segment") {
          this.replacementSegment.winningAnimationDelay = winningAnimationDelay;
        } else if (this.selectedSubRule.includes("New Group")) {
          this.replacementGroup[this.selectedSegmentDefinition].winningAnimationDelay = winningAnimationDelay;
        }
      }, 500),
    },
    segmentWinningAnimationSize: {
      get() {
        if (this.selectedSubRule == "New Segment") {
          return this.replacementSegment ? this.replacementSegment.winningAnimationSize : null;
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.replacementGroup && this.replacementGroup[this.selectedSegmentDefinition] 
          ? this.replacementGroup[this.selectedSegmentDefinition].winningAnimationSize : null;
        }
        return null;
      },
      set: _.debounce(function (winningAnimationSize) {
        if (!winningAnimationSize) return;
        if (this.selectedSubRule == "New Segment") {
          this.replacementSegment.winningAnimationSize = winningAnimationSize;
        } else if (this.selectedSubRule.includes("New Group")) {
          this.replacementGroup[this.selectedSegmentDefinition].winningAnimationSize = winningAnimationSize;
        }
      }, 500),
    },
    getSegmentBonus() {
      if (this.selectedSubRule == "New Segment") {
        return this.segmentBonus;
      } else if (this.selectedSubRule.includes("New Group")) {
        return this.replacementGroup[this.selectedSegmentDefinition].bonusSegment;
      }
      return this.segmentBonus;
    },
    spinnerStyle() {
        return this.downloading ? 'pointer-events: none' : '';
    },
    loadingOverlayStyle() {
        return this.downloading ? '' : 'display: none';
    },
    subRuleItems() {
      if (this.selectedRule == "Remove Winning Segment") {
        return this.removeWinningSegmentSubRules;
      } else if (this.selectedRule == "Remove Winning Group") {
        return this.removeWinningGroupSubRules;
      }
      return null;
    },
    impactedSegmentsItems() {
      if (this.shuffleType == "Include") {
        return this.shuffleImpactedSegmentsIncludeList;
      } else if (this.shuffleType == "Exclude") {
        return this.shuffleImpactedSegmentsExcludeList;
      }
      return null;
    },
    subRuleItemsMapped() {
      return this.subRuleItems ? this.subRuleItems.map(item => item.name) : null;
    },
    selectedRuleHint() {
      if (this.selectedRule == "Remove Winning Segment") {
        return this.$t('rules.removeWinningSegmentHelper');
      } else if (this.selectedRule == "Remove Winning Group") {
        return this.$t('rules.removeWinningGroupHelper');
      } else if (this.selectedRule == "Shuffle") {
        return this.$t('rules.shuffleHelper');
      } else if (this.selectedRule == "Custom") {
        return this.$t('rules.customHelper');
      }
      return null;
    },
    selectedSubRuleHint() {
      if (this.selectedRule == "Remove Winning Segment") {
        if (this.selectedSubRule == "Default") {
          return this.$t('rules.removeWinningSegmentSubRules.default');
        } else if (this.selectedSubRule == "Random") {
          return this.$t('rules.removeWinningSegmentSubRules.random');
        } else if (this.selectedSubRule == "New Segment") {
          return this.$t('rules.removeWinningSegmentSubRules.newSegment');
        } else if (this.selectedSubRule.includes("New Group")) {
          return this.$t('rules.removeWinningSegmentSubRules.newGroup');
        } else if (this.selectedSubRule == "Next") {
          return this.$t('rules.removeWinningSegmentSubRules.next');
        } else if (this.selectedSubRule == "Previous") {
          return this.$t('rules.removeWinningSegmentSubRules.previous');
        } 
      } else if (this.selectedRule == "Remove Winning Group") {
        if (this.selectedSubRule == "Default") {
          return this.$t('rules.removeWinningGroupSubRules.default');
        }else if (this.selectedSubRule == "New Segment") {
          return this.$t('rules.removeWinningGroupSubRules.newSegment');
        } else if (this.selectedSubRule && this.selectedSubRule.includes("New Group Random")) {
          return this.$t('rules.removeWinningGroupSubRules.replacementGroupRandom');
        } else if (this.selectedSubRule && this.selectedSubRule.includes("New Group Sequential")) {
          return this.$t('rules.removeWinningGroupSubRules.replacementGroupSequential');
        } else if (this.selectedSubRule == "Replace") {
          return this.$t('rules.removeWinningGroupSubRules.replace');
        }
      }
      return null;
    },
    selectedShuffleTypeHint() {
      return this.shuffleType == "Include" ? this.$t('rules.shuffleIncludeHelper') : this.$t('rules.shuffleExcludeHelper');
    },
    selectedShuffleImpactedSegmentsIncludeListHint() {
      if (this.shuffleImpactedSegment == "Default") {
        return this.$t('rules.shuffleImpactedSegmentsIncludeListHints.default');
      } else if (this.shuffleImpactedSegment == "Winning Group") {
        return this.$t('rules.shuffleImpactedSegmentsIncludeListHints.winningGroup');
      } else if (this.shuffleImpactedSegment == "Selected IDs") {
        return this.$t('rules.shuffleImpactedSegmentsIncludeListHints.selectedIds');
      } else if (this.shuffleImpactedSegment == "Selected Values") {
        return this.$t('rules.shuffleImpactedSegmentsIncludeListHints.selectedValues');
      } else if (this.shuffleImpactedSegment == "Selected Groups") {
        return this.$t('rules.shuffleImpactedSegmentsIncludeListHints.selectedGroups');
      }
      return null;
    },
    selectedShuffleImpactedSegmentsExcludeListHint() {
      if (this.shuffleImpactedSegment == "Default") {
        return this.$t('rules.shuffleImpactedSegmentsExcludeListHints.default');
      } else if (this.shuffleImpactedSegment == "Winning Segment") {
        return this.$t('rules.shuffleImpactedSegmentsExcludeListHints.winningSegment');
      } else if (this.shuffleImpactedSegment == "Winning Group") {
        return this.$t('rules.shuffleImpactedSegmentsExcludeListHints.winningGroup');
      } else if (this.shuffleImpactedSegment == "Selected IDs") {
        return this.$t('rules.shuffleImpactedSegmentsExcludeListHints.selectedIds');
      } else if (this.shuffleImpactedSegment == "Selected Values") {
        return this.$t('rules.shuffleImpactedSegmentsExcludeListHints.selectedValues');
      } else if (this.shuffleImpactedSegment == "Selected Groups") {
        return this.$t('rules.shuffleImpactedSegmentsExcludeListHints.selectedGroups');
      }
      return null;
    },
    impactedSegmentsMapped() {
      return this.impactedSegmentsItems ? this.impactedSegmentsItems.map(item => item.name) : null;
    },
  },
  methods: {
    ...mapActions(["deleteContent", "updateGame", "deleteContents","downloadContent", "formatSharedAssetList", "getContentByPrefixAndTenant",
     "uploadContent", "getAssets", "getAllWheels", "updateUploadContentError", "getGame", "updateSelectedGameRule", "updateSelectedGameSubRule",
    "saveCustomRule", "saveGame", "getContent", "updateCustomGameRuleContentId"]),
    
    parseGameRule(gameRule) {
      switch (gameRule) { 
        case "RemoveWinningSegment":
            return "Remove Winning Segment";
        case "RemoveWinningGroup":
            return "Remove Winning Group";
        default:
            return gameRule;
      } 
    },
    parseShuffleType(type) {
      switch (type) { 
        case "FreezeGroup":
            this.shuffleType = "Exclude";
            this.shuffleImpactedSegment = "Selected Groups";
            break;
        case "FreezeWinningGroup":
            this.shuffleType = "Exclude";
            this.shuffleImpactedSegment = "Winning Group";
            break;
        case "FreezeIds":
            this.shuffleType = "Exclude";
            this.shuffleImpactedSegment = "Selected IDs";
            break;
        case "FreezeValues":
            this.shuffleType = "Exclude";
            this.shuffleImpactedSegment = "Selected Values";
            break;
        case "FreezeWinningSegment":
            this.shuffleType = "Exclude";
            this.shuffleImpactedSegment = "Winning Segment";
            break;
        
        case "ShuffleGroups":
            this.shuffleType = "Include";
            this.shuffleImpactedSegment = "Selected Groups";
            break;
        case "ShuffleWinningGroup":
            this.shuffleType = "Include";
            this.shuffleImpactedSegment = "Winning Group";
            break;
        case "ShuffleIds":
            this.shuffleType = "Include";
            this.shuffleImpactedSegment = "Selected IDs";
            break;
        case "ShuffleSegments":
            this.shuffleType = "Include";
            this.shuffleImpactedSegment = "Selected Values";
            break;
        default:
            break;
      } 
    },
    async loadExistingRuleSettings() {
      const type = this.getSelectedGame.rules.type;
      const rules = this.getSelectedGame.rules;
      if (this.selectedRule == "Remove Winning Segment") {
        if (type == "ReplacementSegment") {
          this.replacementSegment = rules.replacementSegment;
          this.selectedSubRule = "New Segment";
          this.segmentBonus = rules.replacementSegment.bonusSegment
        } else if (type == "ReplacementGroup") {
          this.replacementGroup = rules.replacementGroup;
          this.selectedSubRule = "New Group";
        } else if (type == "ReplaceNext") {
          this.selectedSubRule = "Next";
        } else if (type == "ReplacePrevious") {
          this.selectedSubRule = "Previous";
        } else if (type == "ReplaceRandomSegment") {
          this.selectedSubRule = "Random";
        }
      }

      if (this.selectedRule == "Remove Winning Group") {
        if (type == "ReplacementSegment") {
          this.replacementSegment = rules.replacementSegment;
          this.selectedSubRule = "New Segment";
          this.segmentBonus = rules.replacementSegment.bonusSegment;
        } else if (type.includes("ReplacementGroup")) {
          if (type == "ReplacementGroupRandom") {
            this.selectedSubRule = "New Group Random";
          } else if (type == "ReplacementGroupSequential") {
            this.selectedSubRule = "New Group Sequential";
          }
          this.replacementGroupName = rules.replacementGroupName;
          for (const key in rules.groupsMapping) {
            if (rules.groupsMapping.hasOwnProperty(key)) {
              const mapping = {
                  mapping: key,
                  name: rules.groupsMapping[key]
              };
              this.replacementGroups.push(mapping);
            }
          }
          this.replacementGroup = rules.groups[this.replacementGroupName];
        } else if (type == "Replace") {
          this.selectedSubRule = "Replace";
        }
      }

      if (this.selectedRule == "Shuffle") {
        this.parseShuffleType(rules.type);
        if (rules.list) {
          this.shuffleValueList = rules.list;
          if (this.shuffleImpactedSegment == "Selected IDs") {
            this.shuffleValueList = rules.list.map(item => "Segment " + item + " - " + this.getSelectedGame.segments.find(segment => segment.id == item).value);
          }
        }

      }
      
      if (this.selectedRule == "Custom") {
        this.updateCustomGameRuleContentId(rules.contentId);
        let contentDto = await this.getContent(rules.contentId);
        const customGameJsonString = atob(contentDto.data);
        this.customRuleDraft = customGameJsonString;
        this.customRule = customGameJsonString; 
      }
    },
    async selectCustomGame() {
      this.downloading = true;
      const customGame = this.getGamesList.find(game => game.displayName == this.selectedCustomGame);
      if (customGame != null) {
        await this.getGame(customGame.id);
      }

      this.selectedRule = null;
      this.selectedSubRule = "Default";
      this.selectedCustomGameObject = this.getGamesList.find(game => game.displayName == this.selectedCustomGame);
      this.replacementGroupName = null;
      this.replacementGroups  = [];

      if (this.selectedCustomGameObject) {
        await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/${this.selectedCustomGameObject.noOfSegments}`, tenantId: this.getTenant, update:"updateSegmentOptions"});
        await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/winning-animation`, tenantId: this.getTenant, update:"updateWinningSegmentAnimations"})
        this.segmentImageOptions = [...this.getSegmentOptions].reverse();
        this.segmentImageOptionsCopy = this.segmentImageOptions;
        this.segmentAnimationOptions = [...this.getWinningAnimationsOptions].reverse();
        this.segmentAnimationOptionsCopy = this.segmentAnimationOptions;  
        if (this.getSelectedGame.gameRule) {
          this.selectedRule = this.parseGameRule(this.getSelectedGame.gameRule);
          if (this.getSelectedGame.rules) {
            this.loadExistingRuleSettings();
          } else if (this.selectedRule == "Shuffle" && !this.getSelectedGame.rules) {
            this.shuffleType = "Include";
            this.shuffleImpactedSegment = "Default";
          }
        }
      }
      
      this.downloading = false;
    },
    async applyRule() {
      if (!this.validationChecks()) {
        return;
      }

      this.downloading = true;
      const customGame = this.getGamesList.find(game => game.displayName == this.selectedCustomGame);
      if (customGame != null) {
        await this.getGame(customGame.id);
        let selectedRuleTrimmed = this.selectedRule.replace(/\s+/g, '');
        await this.updateSelectedGameRule(selectedRuleTrimmed);

        if (this.selectedRule == "Remove Winning Segment") {
          let subRule = this.subRuleItems.find(item => item.name == this.selectedSubRule).value;
          if (this.selectedSubRule == "New Segment") {
            await this.updateSelectedGameSubRule({
              type: subRule,
              replacementSegment: {
                value: this.segmentName,
                group: this.replacementSegment.group,
                image: this.segmentImage,
                animation: this.segmentAnimation,
                bonusSegment: this.segmentBonus,
                winningAnimationSize: this.replacementSegment.winningAnimationSize,
                winningAnimationDuration: this.replacementSegment.winningAnimationDuration,
                winningAnimationDelay: this.replacementSegment.winningAnimationDelay
              }
            });
          } else if (this.selectedSubRule.includes("New Group")) {
            await this.updateSelectedGameSubRule({
              type: subRule,
              replacementGroup: this.replacementGroup
            });
          } else if (this.selectedSubRule == "Default") {
            await this.updateSelectedGameSubRule(null);
          } else {
            await this.updateSelectedGameSubRule({
              type: subRule,
            });
          } 
        }
        
        if (this.selectedRule == "Remove Winning Group") {
          let subRule = this.subRuleItems.find(item => item.name == this.selectedSubRule).value;
          if (this.selectedSubRule == "New Segment") {
            await this.updateSelectedGameSubRule({
              type: subRule,
              replacementSegment: {
                value: this.segmentName,
                group: this.replacementSegment.group,
                image: this.segmentImage,
                animation: this.segmentAnimation,
                bonusSegment: this.segmentBonus,
                winningAnimationSize: this.replacementSegment.winningAnimationSize,
                winningAnimationDuration: this.replacementSegment.winningAnimationDuration,
                winningAnimationDelay: this.replacementSegment.winningAnimationDelay
              }
            });
          } else if (this.selectedSubRule.includes("New Group")) {
            const groups = this.getReplacementGroupJsonList();
            const groupMappings = this.getReplacementGroupJsonMappings();
            await this.updateSelectedGameSubRule({
              type: subRule,
              groupsMapping: groupMappings,
              groups: groups,
              replacementGroupName: this.replacementGroupName
            });
          } else if (this.selectedSubRule == "Default") {
            await this.updateSelectedGameSubRule(null);
          } else {
            await this.updateSelectedGameSubRule({
              type: subRule
            });
          }
        }

        if (this.selectedRule == "Shuffle") {
          let subRule = this.impactedSegmentsItems.find(item => item.name == this.shuffleImpactedSegment).value;
          if (subRule != null && subRule != "") {
            if (this.shuffleImpactedSegment == "Selected IDs") {
              await this.updateSelectedGameSubRule({
                type: subRule,
                list: this.shuffleValueList.map(item => item.match(/\d+/)[0])
              });
            } else if (this.shuffleImpactedSegment.includes("Selected")){
              await this.updateSelectedGameSubRule({
                type: subRule,
                list: this.shuffleValueList
              });
            } else {
              await this.updateSelectedGameSubRule({
                type: subRule,
              });
            }
          } else {
            await this.updateSelectedGameSubRule(null);
          }
        }

        if (this.selectedRule == "Custom") {
          await this.updateSelectedGameSubRule({
            contentId: this.getCustomGameRuleContentId,
            ruleFile: "customGameRule.js"
          });
        }

        await this.saveGame("complete");  
        this.$router.push("/rulepreview");
        this.downloading = false;
      } else {
        this.$toast.error("Error loading custom game.");
        this.downloading = false;
      }
    },
    validationChecks() {
      if (this.selectedCustomGame == null) {
        this.$toast.error("Please select a game.");
        return false;
      }
      if (this.selectedRule == null) {
        this.$toast.error("Please select a rule.");
        return false;
      }

      if (this.selectedRule == "Remove Winning Segment" || this.selectedRule == "Remove Winning Group") {
        if (this.selectedSubRule == null) {
          this.$toast.error("Please select a sub-rule.");
          return false;
        }
        if (this.selectedSubRule == "New Segment" && (this.segmentImage == null || this.segmentImage.displayName == null)) {
          this.$toast.error("Please select a segment image.");
          return false;
        }
        if (this.selectedSubRule.includes("New Group")) {
          for (let i = 0; i < this.replacementGroup.length; i++) {
            if (this.replacementGroup[i].image == null || this.replacementGroup[i].image.displayName == null) {
              this.$toast.error("Please select a segment image for all segments.");
              return false;
            }
          }
          if (this.selectedRule == "Remove Winning Group") {
            if (this.replacementGroupName == null) {
              this.$toast.error("Please enter a replacement group name.");
              return false;
            }
            if (this.replacementGroups == null || this.replacementGroups.length == 0) {
              this.$toast.error("Please select group mappings.");
              return false;
            }
            for (let i = 0; i < this.replacementGroups.length; i++) {
              if (this.replacementGroups[i].mapping == null) {
                this.$toast.error("Please select group mappings.");
                return false;
              }
            }
          }
        }
      }

      if (this.selectedRule == "Shuffle") {
        if (this.shuffleImpactedSegment == null) {
          this.$toast.error("Please fill in all fields.");
          return false;
        }
        if (this.showShuffleValueList == true && (this.shuffleValueList == null || this.shuffleValueList.length == 0)) {
          this.$toast.error("Please fill in all fields.");
          return false;
        }

      }

      if (this.selectedRule == "Custom") {
        if (this.customRule == null || this.customRule == "") {
          this.$toast.error("Please enter a custom rule.");
          return false;
        }
      }

      return true;
    },
    loadCustomGames() {
      this.customGames = this.getGamesList.slice();
      this.customGames = this.customGames.filter(game => {
        return game.status == "Complete";
      });
      this.customGames = this.customGames.map(game => {
        return game.displayName;
      });
      this.customGamesCopy = this.customGames.slice();
    },
    searchGames() {
      if (!this.searchGamesTerm) {
        this.customGames = this.customGamesCopy;
      }

      this.customGames = this.customGamesCopy.filter((option) => {
        return option.toLowerCase().indexOf(this.searchGamesTerm.toLowerCase()) > -1;
      });
    },

    sortDate(a, b) {
      const aSplitDate = a.split('/');
      const bSplitDate = b.split('/');
      const aNewDate = aSplitDate[1] + '/' + aSplitDate[0] + '/' + aSplitDate[2];
      const bNewDate = bSplitDate[1] + '/' + bSplitDate[0] + '/' + bSplitDate[2];
      let aDate = new Date(aNewDate).getTime();
      let bDate = new Date(bNewDate).getTime();

      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 0;
      }
    },
    runFilters() {
      this.tableItems = this.getAssetList.slice();
      let thiz = this;
      
      if (this.assetTypeFilter != null && this.assetTypeFilter != '') {
        this.tableItems = this.tableItems.filter(function (asset) {
          return asset.assetType == thiz.assetTypeFilter;
        });
      }

      if (this.fileTypeFilter != null && this.fileTypeFilter != '') {
        this.tableItems = this.tableItems.filter(function (asset) {
          const fileType = thiz.fileTypeFilter == 'Animation' ? 'video' : 'image';
          return asset.mimeType.split('/')[0] == fileType;
        });
      }

      if (this.gameNameFilter != null && this.gameNameFilter != '') {
        this.tableItems = this.tableItems.filter(function (asset) {
          const game = thiz.getGamesList.find(item => item.displayName == thiz.gameNameFilter);
          return asset.groupId == game.groupId;
        });
      }

    },
    assetNameFormatted(item) {
      if (item == null || item.displayName == null) return '';
      return item.displayName.substring(0, item.displayName.lastIndexOf('.'));
    },
    updateAssetDetails() {
      this.updateAssetDialog = false;
      if (this.tagFilter != null && this.tagFilter != '') {
        this.tags.push(this.tagFilter);
        this.tagFilter = "";
        this.runFilters();
      }
    },
    async onNewAssetFileUploaded() {
      if (this.isInvalidFile()) {
        return;
      }
      this.downloading = true;

      const file = this.assetFiles;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise((resolve) => {
        reader.onload = async () => {
          this.newAssetDTO.base64 = reader.result;
          this.newAssetDTO.file = file;
          this.fileUploaded = true;
          resolve();
        };
      });

      this.downloading = false;
    },
    isInvalidFile() {
      if (this.assetFiles.size > 100000000) {
        this.$toast.error("File size should not exceed 100 MB");
        this.assetFiles = [];
        return false;
      }
      if (this.assetFiles.name.includes(".gif")) {
        this.$toast.error("File must not be a GIF");
        return false;
      }

      if (this.newAssetDTO.assetType == 'Idle Animation' || this.newAssetDTO.assetType == 'Winning Animation') {
        if (!this.assetFiles.name.includes(".webm") && !this.assetFiles.name.includes(".mp4") && !this.assetFiles.name.includes(".svg")) {
          this.$toast.error("File format must be an image, webm or mp4");
          this.assetFiles = [];
          return false;
        }
      }
    },
    async onSegmentContentUploaded() {
      for (let i = 0; i < this.segmentFiles.length; i++) {
        if (!this.isSegmentImageFileValid(this.segmentFiles[i])) {
          this.segmentFiles = [];
          return;
        }
      }
      this.segmentUploading = true;
      this.segmentUploadProgress = 0;
      this.segmentUploadedCount = 0;

      for (let i = 0; i < this.segmentFiles.length; i++) {
        const file = this.segmentFiles[i];
        const segmentCount = this.selectedCustomGameObject.noOfSegments;

        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise((resolve) => {
          reader.onload = async () => {
            const base64 = reader.result;

            await this.$store.dispatch("uploadContent", {
              type: `${segmentCount}_segment`,
              displayName: file.name,
              prefix: `${this.getTenant}/segment/${segmentCount}`,
              file: file,
              base64: base64,
              commitMethod: "setSegmentOptions",
            });
            await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/${segmentCount}`, tenantId: this.getTenant, update:"updateSegmentOptions"});

            this.uploadedCount++;
            this.uploadProgress = Math.round(((i + 1) / this.segmentFiles.length) * 100);
            this.segmentImageOptions = [...this.getSegmentOptions].reverse();
            this.segmentImageOptionsCopy = this.segmentImageOptions;
            this.searchSegmentImage();
            resolve();
          };
        });
      }

      this.segmentUploading = false;
      this.segmentUploadProgress = 0;
      this.segmentUploadedCount = 0;
    },
    handleSegmentContentDragOver(event) {
      event.preventDefault();
    },
    handleSegmentContentDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.segmentFiles = [...this.segmentFiles, ...files];
      this.onSegmentContentUploaded();
    },
    async onSegmentAnimationsUploaded() {
      for (let i = 0; i < this.segmentAnimationFiles.length; i++) {
        if (!this.isContentFilesValid(this.segmentAnimationFiles[i])) {
          this.segmentAnimationFiles = [];
          return;
        }
      }
      this.segmentAnimationUploading = true;
      this.segmentAnimationUploadProgress = 0;
      this.segmentAnimationUploadedCount = 0;

      for (let i = 0; i < this.segmentAnimationFiles.length; i++) {
        const file = this.segmentAnimationFiles[i];

        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise((resolve) => {
          reader.onload = async () => {
            const base64 = reader.result;

            await this.$store.dispatch("uploadContent", {
              type: "animation",
              displayName: file.name,
              prefix: `${this.getTenant}/segment/winning-animation`,
              file: file,
              base64: base64,
              commitMethod: "setWinningAnimationsOptions",
            });
            await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/winning-animation`, tenantId: this.getTenant, update:"updateWinningSegmentAnimations"})

            this.uploadedCount++;
            this.uploadProgress = Math.round(((i + 1) / this.segmentAnimationFiles.length) * 100);
            this.segmentAnimationOptions = [...this.getWinningAnimationsOptions].reverse();
            this.segmentAnimationOptionsCopy = this.segmentAnimationOptions;
            this.searchSegmentImage();
            resolve();
          };
        });
      }

      this.segmentAnimationUploading = false;
      this.segmentAnimationUploadProgress = 0;
      this.segmentAnimationUploadedCount = 0;
    },
    handleSegmentAnimationDragOver(event) {
      event.preventDefault();
    },
    handleSegmentAnimationDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.segmentAnimationFiles = [...this.segmentAnimationFiles, ...files];
      this.onSegmentAnimationsUploaded();
    },
    selectSegmentImage(item) {
      this.segmentImage = item
    },
    selectSegmentAnimation(item) {
      this.segmentAnimation = item
    },
    selectSegmentDefinition(index) {
      if (!index) return;
      this.selectedSegmentDefinition = index;
    },
    numberBetweenOneAndHundred(value) {
      if (value === null || value == '') {
        return true; // allow empty value
      }
      if (!(/^[0-9]*$/.test(value))) {
        return 'Value must be a number between 1 and 100';
      }
      const num = parseFloat(value);
      return !isNaN(num) && num >= 1 && num <= 100 || 'Value must be a number between 1 and 100';
    },
    numberGreaterThanZero(value) {
      if (value === null || value == '') {
        return true; // allow empty value
      }
      if (!(/^[0-9]*$/.test(value))) {
        return 'Value must be a number greater than 0';
      }
      const num = parseFloat(value);
      return !isNaN(num) && num >= 0 || 'Value must be a number greater than 0';
    },
    isSegmentImageFileValid(file) {
      if (file.size > 100000000) {
        this.$toast.error("File size should not exceed 100 MB");
        return false;
      }
      if (!file.type.includes("image")) {
        this.$toast.error("File must be an image");
        return false;
      }
      if (file.name.includes(".gif")) {
        this.$toast.error("File must not be a GIF");
        return false;
      }
      return true;
    },
    isContentFilesValid(file) {
      if (file.size > 100000000) {
        this.$toast.error("File size should not exceed 100 MB");
        return false;
      }
      if (!file.type.includes("image") && !file.name.includes(".webm") && !file.name.includes(".mp4") && !file.name.includes(".svg")) {
        this.$toast.error("File format must be an image, webm or mp4");
        return false;
      }
      if (file.name.includes(".gif")) {
        this.$toast.error("File must not be a GIF");
        return false;
      }
      return true;
    },
    searchSegmentImage() {
      if (!this.searchSegmentImageTerm) {
        this.segmentImageOptions = this.segmentImageOptionsCopy;
      }
      
      this.segmentImageOptions = this.segmentImageOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchSegmentImageTerm.toLowerCase()) > -1;
      });
    },
    searchSegmentAnimation() {
      if (!this.searchSegmentAnimationTerm) {
        this.segmentAnimationOptions = this.segmentAnimationOptionsCopy;
      }
      
      this.segmentAnimationOptions = this.segmentAnimationOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchSegmentAnimationTerm.toLowerCase()) > -1;
      });
    },
    addSegment() {
      if (this.replacementGroup.length > 54) {
        return;
      }
      let newSegment = Object.assign({}, EMPTY_SEGMENT_OBJECT);
      newSegment.name = this.replacementGroup.length == 0 ? "Segment 1" : "Segment " + Number(this.replacementGroup.length + 1);
      if (this.selectedRule == "Remove Winning Group" && this.selectedSubRule.includes("New Group")) {
        newSegment.group = this.replacementGroupName;
      }
      newSegment.value = newSegment.name;
      newSegment.winningAnimationDuration = this.segmentWinningAnimationDuration;
      newSegment.winningAnimationDelay = this.segmentWinningAnimationDelay;
      newSegment.winningAnimationSize = this.segmentWinningAnimationSize;

      this.replacementGroup.push(newSegment);
      this.selectedSegmentDefinition = this.replacementGroup.length - 1;
    },
    deleteSelectedSegmentDefinition() {
      this.deleteSegmentDialog = false;
      this.replacementGroup.splice(this.selectedSegmentDefinition, 1);
      this.selectedSegmentDefinition = 0;
    },
    setSegmentBonus(bonus) {
      if (this.selectedSubRule == "New Segment") {
        this.segmentBonus = bonus;
      } else if (this.selectedSubRule.includes("New Group")) {
        this.replacementGroup[this.selectedSegmentDefinition].bonusSegment = bonus;
      }
    },
    async selectedRuleChanged() {
      if (this.selectedRule == "Shuffle") {
        this.resetShuffleValueList();
      }
      this.selectedSubRule = "Default";
      this.replacementGroupName = null;
      this.replacementGroups = [];
      this.segmentImageOptions = [...this.getSegmentOptions].reverse();
      this.segmentImageOptionsCopy = this.segmentImageOptions;
      this.segmentAnimationOptions = [...this.getWinningAnimationsOptions].reverse();
      this.segmentAnimationOptionsCopy = this.segmentAnimationOptions;

      if (this.replacementSegment == null || this.replacementGroup.length == 0) {
        this.addSegment();
        this.replacementSegment = Object.assign({}, EMPTY_SEGMENT_OBJECT);
        this.replacementSegment.value = "Segment 1";
      }
    },
    resetShuffleValueList() {
      this.shuffleValueList = null;
    },
    async selectedSubRuleChanged() {
      if (this.selectedRule == "Remove Winning Group" && this.selectedSubRule.includes("New Group")) {
        this.replacementGroups = [];
        const segmentGroups = this.getSelectedGame.segments
          .map(segment => segment.group)
          .filter(group => group !== undefined && group != null && group != '');
        this.replacementGroups = [...new Set(segmentGroups)];
        this.replacementGroups = this.replacementGroups.map(group => ({'name': group, 'mapping': null}));
      } else {
        this.replacementGroupName = null;
        this.replacementGroups = [];
      }
    },
    setReplacementGroup() {
      this.replacementGroups = [];
      const segmentGroups = this.getSelectedGame.segments
        .map(segment => segment.group)
        .filter(group => group !== undefined && group !== null && group != '');
      this.replacementGroups = [...new Set(segmentGroups)];
      if (this.replacementGroupName != null) {
        this.replacementGroups.unshift(this.replacementGroupName);
      }
      this.replacementGroups = this.replacementGroups.map(group => ({'name': group, 'mapping': null}));

      for (let i = 0; i < this.replacementGroup.length; i++) {
        this.replacementGroup[i].group = this.replacementGroupName;
      }
      this.watchTrigger = !this.watchTrigger;
    },
    filteredGroupNames(groupName) {
      return this.replacementGroups
        .filter(group => group.name !== groupName)
        .map(group => group.name);
    },
    getReplacementGroupJsonList() {
      let groups = {};
      for (let i = 0; i < this.getSelectedGame.segments.length; i++) {
        if (this.getSelectedGame.segments[i].group) {
          const segmentFirstGroup = this.getSelectedGame.segments[i].group.split(',')[0].trim();
          let segmentCopy = Object.assign({}, this.getSelectedGame.segments[i]);
          segmentCopy.group = segmentFirstGroup;
          if (!groups[segmentFirstGroup]) {
            groups[segmentFirstGroup] = [];
          }
          groups[segmentFirstGroup].push(segmentCopy);
        }
      }
      groups[this.replacementGroupName] = this.replacementGroup;
      return groups;
    },
    getReplacementGroupJsonMappings() {
      const jsonMappings = {};
      for (let i = 0; i < this.replacementGroups.length; i++) {
        jsonMappings[this.replacementGroups[i].name] = this.replacementGroups[i].mapping;
      }
      return jsonMappings;
    },
    async saveCustomRule() {
      this.customRule = this.customRuleDraft;
      const file = new File([this.customRule], 'customGameRule.js', { type: 'text/plain' });

      await this.$store.dispatch("uploadContent", {
        type: `customRule`,
        displayName: file.name,
        prefix: `${this.getTenant}/customRule`,
        file: file,
        commitMethod: "setCustomRuleContentId",
      }).then(() => {
        this.customRule = this.customRuleDraft;
        this.$toast.success("Custom rule saved successfully.");
      });
    }
  }
}
</script>

<style scoped>
#loadingOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.selectWidth {
  max-width: 380px;
}
.searchWidth {
  max-width: 500px;
}
.file_upload ::v-deep .v-input__slot {
  height: 100px !important;
  width: 100%;
  background: #f8f8f8 !important;
  border-radius: 15px;
  overflow: auto;
}

.file_upload ::v-deep .v-label{
  color: #808080;
}

.file_upload ::v-deep .v-input__slot {
  height: 100px !important;
  width: 100%;
  background: #f8f8f8 !important;
  border-radius: 15px;
  overflow: auto;
}

.file_upload ::v-deep .v-label{
  color: #808080;
}

.v-file-input__slot::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #c4c4c4;
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.active{
    background: black !important;
    color: white !important;
}
#yesBonusButton.yes_button_nobonus.v-btn{
    background: #E2FBE0 !important;
    opacity: 100% !important;
    padding-right: 10px !important;
    margin-right: -4px;
    border-width: thin;
    border-color: #64B65C !important;
}
.yes_button_bonus.v-btn{
    opacity: 100% !important;
    border-top-right-radius: inherit !important;
    border-bottom-right-radius: inherit !important;
    z-index: 1;
    background: black !important;
    color: white !important;
}
.no_button_nobonus.v-btn{
    opacity: 100% !important;
    border-top-left-radius: inherit !important;
    border-bottom-left-radius: inherit !important;
    z-index: 1;
    background: black !important;
    color: white !important;
}
#noBonusButton.no_button_bonus.v-btn{
    background: #F9CFCF !important;
    opacity: 100% !important;
    padding-left: 10px !important;
    margin-left: -3px;
    border-width: thin;
    border-color: #F25858 !important;
}
.segment_value_tooltip_width {
  max-width: 800px;
}
</style>