<template>
    <div>
        <v-row>
            <div id="loadingOverlay" :style="loadingOverlayStyle">
                <div class="loader" :style="spinnerStyle"></div>
            </div>
            <v-card class="elevation-0">
                <v-card-title class="font-weight-normal">{{ $t('details.buildWheelHeader') }}</v-card-title>
                <v-card-subtitle class="font-weight-light">{{ $t('details.buildWheelSubtitle') }}</v-card-subtitle>
            </v-card>
        </v-row>
        <v-row>
            <v-col cols="2" :md="2" class="ma-4">
                <v-btn @click="triggerGameConfirmation" class="primary elevation-0 mr-2" :class="gameId ? '' : 'disabledBtn'">{{ $t('action.buildGame') }}<v-icon class="ml-4">mdi-wrench</v-icon></v-btn>
            </v-col>
            <v-col cols="2" :md="2" class="ma-4">
                <v-btn @click="downloadGame" class="primary elevation-0 mr-2" :class="gameId && presignedUrl ? '' : 'disabledBtn'">{{ $t('action.downloadGame') }}<v-icon class="ml-4">mdi-folder-download-outline</v-icon></v-btn>
            </v-col>
            <v-col cols="2" :md="2" class="ma-4">
                <v-btn @click="redirectGameRules" class="primary elevation-0 mr-2" :class="gameId && presignedUrl ? '' : 'disabledBtn'">{{ $t('action.goToRules') }}<v-icon class="ml-4">mdi-arrow-right-thick</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="confirmDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline align-self-center text-center">{{ $t('action.confirmGame') }}</v-card-title>
                <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('details.wheelName') }}:</b> {{ getSelectedGame.name }}</v-card-text>
                <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('details.numberOfTiers') }}:</b> {{ getSelectedGame.tiers ? getSelectedGame.tiers.length + 1 : 1 }}</v-card-text>
                <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('details.tierOneSegmentCount') }}:</b> {{ getSelectedGame.no_of_segments }}</v-card-text>
                <v-card-text class="black--text pt-0 pb-2 pl-10"> <b>{{ $t('details.bonusSegmentCount') }}:</b> {{ getNumberOfBonusSegments(getSelectedGame.segments) }} </v-card-text>

                <div
                    class="pt-0"
                    v-for="(tier, index) in getSelectedGame.tiers"
                    :key="`tier_${index}`"
                >
                    <v-card-text class="pt-0 pb-2 pl-10"> <b>Tier {{ index + 2 }} Segment Count:</b> {{ getSelectedGame.tiers[index].segments.length }}</v-card-text>
                    <v-card-text class="pt-0 pb-2 pl-10"> <b>{{ $t('details.bonusSegmentCount') }}:</b> {{ getNumberOfBonusSegments(getSelectedGame.tiers[index].segments) }}</v-card-text>
                </div>
                
                <v-card-actions>
                    <v-btn color="primary" text @click="buildGame">Yes</v-btn>
                    <v-btn color="red" text @click="confirmDialog = false;">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            timerDisable: false,
            downloading: false,
            confirmDialog: false,
            enableDownload: false,
            presignedUrl: null
        }
    },
    mounted() {
        this.presignedUrl = this.getSelectedGame.presignedUrl;
    },
    computed: {
        ...mapGetters(['getGameJSON', "getSelectedGame", "getPresignedUrl", "getBeforeUnloadEventFunction"]),
        gameJSON(){
            return this.getGameJSON && this.getGameJSON.segments && this.getGameJSON.segments.length >=3;
        },
        gameId(){
            return this.getSelectedGame ? this.getSelectedGame.wheelGameId : null;
        },
        spinnerStyle() {
            return this.downloading ? 'pointer-events: none' : '';
        },
        loadingOverlayStyle() {
            return this.downloading ? '' : 'display: none';
        }
    },
    methods: {
        ...mapActions(["generateGameJSON", "uploadWheelGame", "downloadGameZip", "saveGame", "deleteContent", "uploadGameZip", "getGame"]),
        getNumberOfBonusSegments(segments) {
            let count = 0;
            segments.forEach(segment => {
                if (segment.bonusSegment) count++;
            });
            return count;
        },
        triggerGameConfirmation() {
            this.confirmDialog = true;
        },
        async buildGame() {
            this.downloading = true;
            this.confirmDialog = false;
            await this.generateGameJSON();
            await this.uploadWheelGame({'incompleteGame' : false});
            if (this.getSelectedGame.status == "Draft") {
                await this.deleteContent(this.getSelectedGame.savedGameId);
            }
            await this.saveGame("complete");  
            await this.uploadGameZip();
            this.$toast.success("Your game has been added to the queue. Download will be available once it has been built.");
            this.downloading = false;
        },
        async downloadGame() {
            window.removeEventListener('beforeunload', this.getBeforeUnloadEventFunction);
            window.location.href = this.presignedUrl;
            window.addEventListener('beforeunload', this.getBeforeUnloadEventFunction);
        },
        setPresignedUrl(url) {
            this.presignedUrl = url;
        },
        async redirectGameRules() {
            await this.getGame(this.getSelectedGame.savedGameId);
            this.$router.push({ name: 'gameRules' });
        }
    }
}
</script>
<style scoped>
.disabledBtn {
    pointer-events:none;
    opacity: 0.4;
}
#loadingOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>