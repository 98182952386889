<template>
    <v-navigation-drawer app absolute permanent left class="background" height="100%" :width="$vuetify.breakpoint.mdAndUp ? 180 : 70">
        <img :class="$vuetify.breakpoint.mdAndUp ? 'ml-6 mr-8 mt-2 mb-12' : 'ml-1 mr-1 mt-2 mb-12'" :src="require('@/assets/DigiWheel_Logo_Evolution_Company_RGB_Yellow_Black.svg')" alt="logo">

        <v-list dense nav class="mb-4">
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                @click="goToPage(item.path)"
            >
              <v-list-item-icon :class="$vuetify.breakpoint.mdAndUp ? 'mx-4 background--text' : 'mx-2 background--text'">
                  <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
      </v-list>

    </v-navigation-drawer>
</template>

<script>
  export default {
    name: "NavigationDrawer",
    data () {
      return {
        items: [
          { title: 'Games', icon: 'mdi-dots-triangle', path: '/'},
           { title: 'Game Rules', icon: 'mdi-clipboard-edit-outline', path: '/gamerules' },
           { title: 'Asset Library', icon: 'mdi-image-outline', path: '/assets' },
          // { title: 'Templates', icon: 'mdi-text-box-outline', path: '' },
          // { title: 'Demo Wheels', icon: 'mdi-web', path: '' },
        ],
        right: null,
      }
    },
    methods: {
      goToPage(path) {
        this.$router.push({ path: path, replace: true });
        window.location.reload();
      }
    }
  }

  
</script>
