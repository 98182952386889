import { createLogger } from "vuex";
import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/modules/state'
import login from '@/modules/login'
import actions from '@/modules/actions';
import getters from '@/modules/getters';
import mutations from '@/modules/mutations';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    login: login,
    game: {
      state,
      mutations,
      actions,
      getters
    }
  },
  
  strict: debug,
  // plugins: debug ? [createLogger()] : [],
});
