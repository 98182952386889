import { EMPTY_CONTENT_OBJECT, randomPastelHex } from '@/utils/helper';


export default { 
    gamesList: [],
    assetList: [],
    sharedAssetList: [],

    selectedGame: {
        wheelGameId: null,
        savedGameId: null,
        name: null,
        description: null,
        baseWheel: null,
        selectedTier: 0,
        animationSize: 100,
        selectedGameRule: null,
        selectedGameSubRule: null,
        
        //properties for rendering but not yet configurable via the UI
        drawMode: "code",
        lastManStanding: false,

        //Media Options to populate select dropdowns - on game selection trigger a retrieval of media options
        tierOptions: [],
        idleAnimationOptions: [],
        contentOptions: [],
        backgroundOptions: [],
        animationOptions: [],
        segmentOptions: [],
        winningAnimationsOptions: [],

        //Media set via UI
        idle: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            delay: "50", //default value
            duration: "20"
        },
        animation: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            startupAsset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            startupAnimationDuration: "50",
            startupAnimationDelay: "5",
            startupAnimationLoop: false,
            winningAnimationDuration: "5"
        },
        background: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50",
            rotation: "Counter"
        },
        backgroundOverlay: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50",
            rotation: "None"
        },
        wheelOverlay: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50",
            rotation: "None"
        },
        foreground: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50",
            rotation: "Counter"
        },

        //Segments
        no_of_segments: null,
        segment_definitions:[
            {
                id: 0,
                name: "Segment 1",
                bonusSegment: false,
                image: {
                    id: 0,
                    file: null,
                    base64: null,
                    contentId: null
                },
                animation: {
                    id: 0,
                    file: null,
                    base64: null,
                    contentId: null
                },
                fillStyle: randomPastelHex()
            },
            {
                id: 1,
                name: "Segment 2",
                bonusSegment: false,
                image: {
                    id: 1,
                    file: null,
                    base64: null,
                    contentId: null
                },
                animation: {
                    id: 1,
                    file: null,
                    base64: null,
                    contentId: null
                },
                fillStyle: randomPastelHex()
            },
            {
                id: 2,
                name: "Segment 3",
                bonusSegment: false,
                image: {
                    id: 2,
                    file: null,
                    base64: null,
                    contentId: null
                },
                animation: {
                    id: 2,
                    file: null,
                    base64: null,
                    contentId: null
                },
                fillStyle: randomPastelHex(),
            }
        ],
        segments: [],
        
        //Tiers - after base wheel add all tiers here 
        tiers: []
    },

    gameJSON: {},
    
    currentStage: 1,
    uploadContentError: null,
    dropdownsLoaded: false,
    customGameRuleContentId: null,
    beforeUnloadEventFunction: function(event) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave?';
    }
};