import Stomp from "webstomp-client";
import en from "../locale/en";

export function connectContentService (callbacks, port = "8083") {

    var defaultCallbacks = {
        handleGameZipBuilt: null,
        handleGameZipUrlExpired: null
    };

    for (let key in callbacks) {
        if (Object.prototype.hasOwnProperty.call(callbacks, key)) {
            defaultCallbacks[key] = callbacks[key];
        }
    }

    let stompClient = contentServiceSocket("ws", port);

    stompClient.connect(
        {},
        () => {
            console.log("Connecting to Content Service WS");
            if(window.java !== null && window.java !== undefined) {
                window.java.clearErrorAlert();
            }

            stompClient.subscribe("/topic/wizard/gameZipBuilt", (tick) => {
                if (defaultCallbacks.handleGameZipBuilt) {
                    var response = JSON.parse(tick.body);
                    defaultCallbacks.handleGameZipBuilt(response);
                }
            });

            stompClient.subscribe("/topic/wizard/gameZipUrlExpired", (tick) => {
                if (defaultCallbacks.handleGameZipUrlExpired) {
                    var response = JSON.parse(tick.body);
                    defaultCallbacks.handleGameZipUrlExpired(response);
                }
            });
    
        },
        (error) => {
            console.log("Error Connecting to Content Service WS: " + error);
            if(window.java !== null && window.java !== undefined) {
                window.java.errorAlert(`Error ${error.code}: Content Service Connection lost. Please check your network connection.`);
            }
            setTimeout(() => {
                connectContentService (callbacks)
            }, 1000);
        }
    );

    return stompClient;
}

function contentServiceSocket (endpoint, port = "8083") {
    let _port = port;

    if(typeof port !== "string"){
        _port = "8083";
    }

    let contentServiceIP = "localhost";
    if (en && en.app && en.app.contentServiceIP) {
        contentServiceIP = en.app.contentServiceIP;
    }

    let stompClient = "";
    if (contentServiceIP == "localhost") {
        stompClient = Stomp.client(`ws://${contentServiceIP}:${_port}/` + endpoint);
    } else {
        stompClient = Stomp.client(`wss://${contentServiceIP}/` + endpoint);
    }
    
    stompClient.heartbeat.outgoing = 1000;
    stompClient.heartbeat.incoming = 1000;

    return stompClient;
}